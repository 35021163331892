import React from "react";
import QrReader from "react-qr-reader";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";

const QRModal = (props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  scanType: string;
  handleScan: (data: any) => void;
}) => {
  const { open, setOpen, scanType, handleScan } = props;
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.modalContainer}>
          <h2 id="transition-modal-title">Scan {scanType === "bundle" ? "Set" : "Item"} number</h2>
          <QrReader
            delay={300}
            onError={(err) => {
              console.log(err);
            }}
            onScan={handleScan}
            style={{ width: "100%" }}
          />
        </div>
      </Fade>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContainer: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 0,
    width: "90vw",
    maxWidth: "500px",
  },
}));

export default QRModal;
