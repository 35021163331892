import { AUTH_OK, USER_LOGOUT } from "./types";
import { Dispatch } from "redux";
import axios from "axios";

export const logout = () => {
  return {
    type: USER_LOGOUT,
  };
};

export const loginWithUsernameAndPassword = (
  username: string,
  password: string
) => async (dispatch: Dispatch) => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/auth/login`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true,
      data: {
        username,
        password,
      },
    });
    if (response.status === 200) {
      let data = await response.data;
      dispatch({
        type: AUTH_OK,
        payload: {
          accessToken: data.access_token,
          role: data.role,
          name: data.name,
          username: data.username,
        },
      });
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const changePassword = (state: {
  username: string;
  oldPassword: string;
  newPassword: string;
}): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/auth/reset`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      data: {
        username: state.username,
        oldPassword: state.oldPassword,
        newPassword: state.newPassword,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data.success);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
