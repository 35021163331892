import axios from "axios";
import { AUTH_ERROR, AUTH_FORBIDDEN } from "../redux/actions/types";
import { store } from "../redux/store";

const { dispatch } = store;

axios.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.accessToken;
    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if ([400, 401, 403].indexOf(error.response.status) !== -1) {
        console.log("Unauthorised");
        dispatch({ type: AUTH_FORBIDDEN, payload: "Unauthorised" });
      } else if (error.response.status === 500) {
        console.log(error);
        return Promise.reject(error);
      }
    } else {
      dispatch({ type: AUTH_ERROR, payload: error.response });
    }
    return Promise.reject(error);
  }
);
