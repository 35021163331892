import axios from "axios";

export const getItemsOut = (): Promise<string[]> => {
  return new Promise<string[]>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/eq/itemsout`,
      method: "GET",
      withCredentials: true,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSerialNumbersOut = (itemName: string): Promise<string[]> => {
  return new Promise<string[]>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/eq/serialnumberout`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      data: { itemname: itemName },
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postItemWithdrawal = (
  itemname: string,
  serialnumber: string,
  project: string,
  issueto: string,
  remarks: string
): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/eq/storewithdrawals`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      data: {
        itemname,
        serialnumber,
        project,
        issueto,
        remarks,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data.success);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
