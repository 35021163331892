import React, { FormEvent } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { GeneratorItem } from "../../../models/generatorItem";

const ReturnItemForm = (props: {
  item: GeneratorItem;
  submitLoading: boolean;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}) => {
  const { item, submitLoading, handleSubmit } = props;
  return (
    <Paper style={{ padding: 10, marginTop: 20 }}>
      <div style={{ margin: 20 }}>
        <form onSubmit={handleSubmit}>
          <TextField
            name="brand"
            variant="outlined"
            margin="normal"
            id="brand"
            label="Brand"
            value={item.brand || ""}
            type="text"
            required
            disabled
            fullWidth
          />
          <TextField
            name="capacity"
            variant="outlined"
            margin="normal"
            id="capacity"
            label="Capacity"
            value={item.capacity || ""}
            type="text"
            required
            disabled
            fullWidth
          />
          <TextField
            name="model"
            variant="outlined"
            margin="normal"
            id="model"
            label="Model"
            value={item.model || ""}
            type="text"
            required
            disabled
            fullWidth
          />
          <TextField
            name="phase"
            variant="outlined"
            margin="normal"
            id="phase"
            label="Phase"
            value={item.phase || ""}
            type="text"
            required
            disabled
            fullWidth
          />
          <TextField
            name="serialNumber"
            variant="outlined"
            margin="normal"
            id="serialNumber"
            label="Serial Number"
            value={item.serialNumber || ""}
            type="text"
            required
            disabled
            fullWidth
          />
          {!submitLoading && (
            <Button
              fullWidth
              disableElevation
              type="submit"
              variant="contained"
              color="secondary"
              style={{ marginTop: 20 }}
            >
              Return Item
            </Button>
          )}
          {submitLoading && (
            <div style={{ textAlign: "center", margin: 20 }}>
              <CircularProgress color="primary" size={30} />
            </div>
          )}
        </form>
      </div>
    </Paper>
  );
};

export default ReturnItemForm;
