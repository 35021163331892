import axios from "axios";
import moment from "moment-timezone";
import { GeneratorItem } from "../../models/generatorItem";

export const getItems = (): Promise<GeneratorItem[]> => {
  return new Promise<GeneratorItem[]>((resolve, reject) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/generators/items`,
      withCredentials: true,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        let items: GeneratorItem[] = data.map((x: any) => {
          return {
            sn: x.sn,
            brand: x.brand,
            capacity: x.capacity,
            model: x.model,
            phase: x.phase,
            serialNumber: x.serial_number,
            project: x.project,
            location: x.location,
            emaLicense: x.ema_license,
            startDate: x.start_date,
            status: x.status,
            endDate: x.end_date,
            cost: x.cost,
            value: x.value,
            deposit: x.deposit,
            priceWeek: x.price_week,
            priceMonth: x.price_month,
            priceAnnual: x.price_annual,
            timeStamp: moment
              .utc(x.time_stamp)
              .local()
              .format("DD/MM/YYYY, HH:mm:ss"),
            working: x.working ? "Working" : "Not Working",
          };
        });
        resolve(items);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getItemById = (id: number): Promise<GeneratorItem> => {
  return new Promise<GeneratorItem>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/generators/items/${id}`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((res) => res.data)
      .then((x) => {
        console.log("API OK");
        let item: GeneratorItem = {
          sn: x.sn,
          brand: x.brand,
          capacity: x.capacity,
          model: x.model,
          phase: x.phase,
          serialNumber: x.serial_number,
          project: x.project,
          location: x.location,
          emaLicense: x.ema_license,
          startDate: x.start_date,
          status: x.status,
          endDate: x.end_date,
          cost: x.cost,
          value: x.value,
          deposit: x.deposit,
          priceWeek: x.price_week,
          priceMonth: x.price_month,
          priceAnnual: x.price_annual,
          timeStamp: x.time_stamp,
          working: x.working,
        };
        resolve(item);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postItem = (item: GeneratorItem): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/generators/items`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      data: {
        brand: item.brand,
        capacity: item.capacity,
        model: item.model,
        phase: item.phase,
        serialNumber: item.serialNumber,
        project: item.project,
        location: item.location,
        emaLicense: item.emaLicense,
        cost: item.cost,
        value: item.value,
        deposit: item.deposit,
        priceWeek: item.priceWeek,
        priceMonth: item.priceMonth,
        priceAnnual: item.priceAnnual,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data.success);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateItem = (item: GeneratorItem): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/generators/items`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      data: {
        sn: item.sn,
        brand: item.brand,
        capacity: item.capacity,
        model: item.model,
        phase: item.phase,
        serialNumber: item.serialNumber,
        project: item.project,
        location: item.location,
        emaLicense: item.emaLicense,
        cost: item.cost,
        value: item.value,
        deposit: item.deposit,
        priceWeek: item.priceWeek,
        priceMonth: item.priceMonth,
        priceAnnual: item.priceAnnual,
        startDate: item.startDate,
        endDate: item.endDate,
        working: item.working,
        status: item.status,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data.success);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteItem = (sn: number): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/generators/items/${sn}`,
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data.success);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postItemWithdrawal = (
  sn: number,
  project: string,
  location: string,
  startDate: string,
  endDate: string
): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/generators/storewithdrawals`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      data: {
        sn,
        project,
        location,
        startDate,
        endDate,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data.success);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postItemReturn = (sn: number): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/generators/storereturns`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      data: {
        sn,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data.success);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
