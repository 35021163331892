import React, { FormEvent, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { EquipmentItem } from "../../../models/equipmentItem";

const UpdateItemForm = (props: {
  item: EquipmentItem;
  submitLoading: boolean;
  updateForm: (
    event:
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | { target: { name: string; value: string } }
  ) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}) => {
  const { item, submitLoading, updateForm, handleSubmit } = props;
  const [workingButton, setWorkingButton] = useState(
    item.working ? item.working : "true"
  );
  const handleToggle = (e: any, newButton: string, setFunction: Function) => {
    if (newButton !== null) setFunction(newButton);
  };
  return (
    <Paper style={{ padding: 10, marginTop: 20 }}>
      <div style={{ margin: 20 }}>
        <form onSubmit={handleSubmit}>
          <TextField
            name="itemName"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Item Name"
            value={item.itemName || ""}
            onChange={updateForm}
            autoFocus
          />
          <TextField
            name="category"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="type"
            label="Item Type"
            value={item.category || ""}
            onChange={updateForm}
          />
          <TextField
            name="bundle"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="set"
            label="Item Set"
            value={item.bundle || ""}
            onChange={updateForm}
          />
          <TextField
            name="imageUrl"
            variant="outlined"
            margin="normal"
            fullWidth
            id="imageUrl"
            label="Image URL"
            value={item.imageUrl || ""}
            onChange={updateForm}
          />
          <TextField
            name="model"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="model"
            label="Model"
            value={item.model || ""}
            onChange={updateForm}
          />
          <TextField
            name="serialNumber"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="serialNumber"
            label="Serial Number"
            value={item.serialNumber || ""}
            onChange={updateForm}
          />
          <TextField
            name="status"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="status"
            label="Status"
            value={item.status || ""}
            onChange={updateForm}
          />
          <TextField
            name="project"
            variant="outlined"
            margin="normal"
            fullWidth
            id="project"
            label="Project"
            value={item.project || ""}
            onChange={updateForm}
          />
          <TextField
            name="issueTo"
            variant="outlined"
            margin="normal"
            fullWidth
            id="issueTo"
            label="Issue To"
            value={item.issueTo || ""}
            onChange={updateForm}
          />
          <TextField
            name="returnBy"
            variant="outlined"
            margin="normal"
            fullWidth
            id="returnBy"
            label="Return By"
            value={item.returnBy || ""}
            onChange={updateForm}
          />
          <TextField
            name="remarks"
            variant="outlined"
            margin="normal"
            fullWidth
            id="remarks"
            label="Remarks"
            value={item.remarks || ""}
            onChange={updateForm}
          />
          <div style={{ textAlign: "center" }}>
            <ToggleButtonGroup
              onChange={(event: any, value: string) => {
                handleToggle(event, value, setWorkingButton);
                updateForm({
                  target: {
                    name: "working",
                    value: value,
                  },
                });
              }}
              value={workingButton}
              exclusive
              aria-label="text alignment"
            >
              <ToggleButton
                size="medium"
                value="true"
                onClick={() => setWorkingButton("true")}
              >
                Working
              </ToggleButton>
              <ToggleButton
                size="medium"
                value="false"
                onClick={() => setWorkingButton("false")}
              >
                Not Working
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          {!submitLoading && (
            <Button
              fullWidth
              disableElevation
              type="submit"
              variant="contained"
              color="secondary"
              style={{ marginTop: 20 }}
            >
              Edit Item
            </Button>
          )}
          {submitLoading && (
            <div style={{ textAlign: "center", margin: 20 }}>
              <CircularProgress color="primary" size={30} />
            </div>
          )}
        </form>
      </div>
    </Paper>
  );
};

export default UpdateItemForm;
