import React, { FormEvent } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateFnsUtils from "@date-io/date-fns";
import { GeneratorItem } from "../../../models/generatorItem";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const WithdrawItemForm = (props: {
  item: GeneratorItem;
  submitLoading: boolean;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  state: {
    project: string | null;
    location: string | null;
    startDate: string | null;
    endDate: string | null;
  };
  updateForm: (
    event:
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | { target: { name: string; value: string } }
  ) => void;
}) => {
  const { item, submitLoading, handleSubmit, state, updateForm } = props;
  const handleDateChange = (name: string, date: Date | null) => {
    if (date && date.toJSON()) {
      let formattedDate = date.toJSON().slice(0, 10);
      console.log(formattedDate);
      updateForm({ target: { name: name, value: formattedDate } });
    }
  };
  return (
    <Paper style={{ padding: 10, marginTop: 20 }}>
      <div style={{ margin: 20 }}>
        <form onSubmit={handleSubmit}>
          <TextField
            name="brand"
            variant="outlined"
            margin="normal"
            id="brand"
            label="Brand"
            value={item.brand || ""}
            type="text"
            required
            disabled
            fullWidth
          />
          <TextField
            name="capacity"
            variant="outlined"
            margin="normal"
            id="capacity"
            label="Capacity"
            value={item.capacity || ""}
            type="text"
            required
            disabled
            fullWidth
          />
          <TextField
            name="model"
            variant="outlined"
            margin="normal"
            id="model"
            label="Model"
            value={item.model || ""}
            type="text"
            required
            disabled
            fullWidth
          />
          <TextField
            name="phase"
            variant="outlined"
            margin="normal"
            id="phase"
            label="Phase"
            value={item.phase || ""}
            type="text"
            required
            disabled
            fullWidth
          />
          <TextField
            name="serialNumber"
            variant="outlined"
            margin="normal"
            id="serialNumber"
            label="Serial Number"
            value={item.serialNumber || ""}
            type="text"
            required
            disabled
            fullWidth
          />
          <TextField
            name="project"
            variant="outlined"
            margin="normal"
            id="project"
            label="Project"
            value={state.project || ""}
            onChange={updateForm}
            type="text"
            required
            fullWidth
          />
          <TextField
            name="location"
            variant="outlined"
            margin="normal"
            id="location"
            label="Location"
            value={state.location || ""}
            onChange={updateForm}
            type="text"
            required
            fullWidth
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              required
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              id="startDate"
              label="Start Date"
              name="startDate"
              value={state.startDate}
              onChange={(date: Date | null) =>
                handleDateChange("startDate", date)
              }
              KeyboardButtonProps={{
                "aria-label": "start date",
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              required
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              id="endDate"
              label="End Date"
              name="endDate"
              value={state.endDate}
              onChange={(date: Date | null) =>
                handleDateChange("endDate", date)
              }
              KeyboardButtonProps={{
                "aria-label": "end date",
              }}
            />
          </MuiPickersUtilsProvider>
          {!submitLoading && (
            <Button
              fullWidth
              disableElevation
              type="submit"
              variant="contained"
              color="secondary"
              style={{ marginTop: 20 }}
            >
              Withdraw Item
            </Button>
          )}
          {submitLoading && (
            <div style={{ textAlign: "center", margin: 20 }}>
              <CircularProgress color="primary" size={30} />
            </div>
          )}
        </form>
      </div>
    </Paper>
  );
};

export default WithdrawItemForm;
