import React from "react";
import Grid from "@material-ui/core/Grid";
import Transactions from "../Inventory/Transactions";
import Items from "../Inventory/Items";
import CreateItem from "../Inventory/CreateItem";
import UpdateItem from "../Inventory/UpdateItem";
import DeleteItem from "../Inventory/DeleteItem";
import Withdrawals from "../Inventory/WithdrawItem";
import Returns from "../Inventory/ReturnItem";
import Delivieries from "../Inventory/Deliveries";

const Inventory = (props: { invtButton: string }) => {
  const { invtButton } = props;
  return (
    <>
      <Grid item xs={12}>
        {invtButton === "transactions" && <Transactions />}
        {invtButton === "withdraw" && <Withdrawals />}
        {invtButton === "return" && <Returns />}
        {invtButton === "add" && <CreateItem />}
        {invtButton === "edit" && <UpdateItem />}
        {invtButton === "remove" && <DeleteItem />}
        {invtButton === "invt" && <Items />}
        {invtButton === "delivery" && <Delivieries />}
      </Grid>
    </>
  );
};

export default Inventory;
