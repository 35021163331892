import React, { FormEvent } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { InventoryItem } from "../../../models/inventoryItem";

const AddItemForm = (props: {
  item: InventoryItem;
  submitLoading: boolean;
  updateForm: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}) => {
  const { item, submitLoading, updateForm, handleSubmit } = props;
  return (
    <Paper style={{ padding: 10 }}>
      <div style={{ margin: 20 }}>
        <h2>Add Inventory Item</h2>
        <form onSubmit={handleSubmit}>
          <TextField
            name="name"
            variant="outlined"
            margin="normal"
            id="name"
            label="Name"
            value={item.name || ""}
            onChange={updateForm}
            type="text"
            required
            fullWidth
          />
          <TextField
            name="imageUrl"
            variant="outlined"
            margin="normal"
            id="imageUrl"
            label="Image URL"
            value={item.imageUrl || ""}
            onChange={updateForm}
            type="text"
            fullWidth
          />
          <TextField
            name="category"
            variant="outlined"
            margin="normal"
            id="category"
            label="Category"
            value={item.category || ""}
            onChange={updateForm}
            type="text"
            required
            fullWidth
          />
          <TextField
            name="price"
            variant="outlined"
            margin="normal"
            id="price"
            label="Price"
            value={item.price || ""}
            onChange={updateForm}
            type="number"
            inputProps={{ step: "any" }}
            required
            fullWidth
          />
          <TextField
            name="units"
            variant="outlined"
            margin="normal"
            id="units"
            label="Units"
            value={item.units || ""}
            onChange={updateForm}
            type="text"
            required
            fullWidth
          />
          <TextField
            name="currentBalance"
            variant="outlined"
            margin="normal"
            id="currentBalance"
            label="Current Balance"
            value={item.currentBalance || ""}
            onChange={updateForm}
            type="number"
            required
            fullWidth
          />
          <TextField
            name="optimalBalance"
            variant="outlined"
            margin="normal"
            id="optimalBalance"
            label="Optimal Balance"
            value={item.optimalBalance || ""}
            onChange={updateForm}
            type="number"
            required
            fullWidth
          />
          <TextField
            name="location"
            variant="outlined"
            margin="normal"
            id="location"
            label="Location"
            value={item.location || ""}
            onChange={updateForm}
            type="text"
            required
            fullWidth
          />
          <TextField
            name="remarks"
            variant="outlined"
            margin="normal"
            id="remarks"
            label="Remarks"
            value={item.remarks || ""}
            onChange={updateForm}
            type="text"
            fullWidth
          />
          {!submitLoading && (
            <Button
              fullWidth
              disableElevation
              type="submit"
              variant="contained"
              color="secondary"
              style={{ marginTop: 20 }}
            >
              Add Item
            </Button>
          )}
          {submitLoading && (
            <div style={{ textAlign: "center", margin: 20 }}>
              <CircularProgress color="primary" size={30} />
            </div>
          )}
        </form>
      </div>
    </Paper>
  );
};

export default AddItemForm;
