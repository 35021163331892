import React, { FormEvent } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

const AddItemForm = (props: {
  state: {
    itemName: string | null;
    category: string | null;
    bundle: string | null;
    imageUrl: string | null;
    model: string | null;
    serialNumber: string | null;
    remarks: string | null;
  };
  submitLoading: boolean;
  updateForm: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}) => {
  const { state, submitLoading, updateForm, handleSubmit } = props;
  return (
    <Paper style={{ padding: 10 }}>
      <div style={{ margin: 20 }}>
        <h2>Add Item </h2>
        <form onSubmit={handleSubmit}>
          <TextField
            name="itemName"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Item Name"
            value={state.itemName || ""}
            onChange={updateForm}
            autoFocus
          />
          <TextField
            name="category"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="type"
            label="Item Type"
            value={state.category || ""}
            onChange={updateForm}
          />
          <TextField
            name="bundle"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="set"
            label="Item Set"
            value={state.bundle || ""}
            onChange={updateForm}
          />
          <TextField
            name="imageUrl"
            variant="outlined"
            margin="normal"
            fullWidth
            id="imageUrl"
            label="Image URL"
            value={state.imageUrl || ""}
            onChange={updateForm}
          />
          <TextField
            name="model"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="model"
            label="Model"
            value={state.model || ""}
            onChange={updateForm}
          />
          <TextField
            name="serialNumber"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="serialNumber"
            label="Serial Number"
            value={state.serialNumber || ""}
            onChange={updateForm}
          />
          <TextField
            name="remarks"
            variant="outlined"
            margin="normal"
            fullWidth
            id="remarks"
            label="Remarks"
            value={state.remarks || ""}
            onChange={updateForm}
          />
          {!submitLoading && (
            <Button
              fullWidth
              disableElevation
              type="submit"
              variant="contained"
              color="secondary"
              style={{ marginTop: 20 }}
            >
              Add Item
            </Button>
          )}
          {submitLoading && (
            <div style={{ textAlign: "center", margin: 20 }}>
              <CircularProgress color="primary" size={30} />
            </div>
          )}
        </form>
      </div>
    </Paper>
  );
};

export default AddItemForm;
