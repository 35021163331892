import React, { FormEvent } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { InventoryItem } from "../../../models/inventoryItem";

const DeliveryForm = (props: {
  item: InventoryItem;
  state: {
    deliveryOrderId: string | null;
    quantity: number | null;
  };
  submitLoading: boolean;
  updateForm: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}) => {
  const { item, state, submitLoading, updateForm, handleSubmit } = props;
  return (
    <Paper style={{ padding: 10, marginTop: 20 }}>
      <div style={{ margin: 20 }}>
        <h2>Delivery for {item.name}</h2>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Item Name"
            value={item.name || ""}
            autoFocus
            disabled
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="type"
            label="Item Type"
            value={item.category || ""}
            autoFocus
            disabled
          />
          <TextField
            name="deliveryOrderId"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="deliveryOrderId"
            label="D/O Number"
            value={state.deliveryOrderId || ""}
            onChange={updateForm}
            autoFocus
          />
          <TextField
            name="quantity"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="quantity"
            label="Quantity"
            type="number"
            value={state.quantity || ""}
            onChange={updateForm}
          />
          {!submitLoading && (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              style={{ marginTop: 20 }}
            >
              Add Delivery
            </Button>
          )}
          {submitLoading && (
            <div style={{ textAlign: "center", margin: 20 }}>
              <CircularProgress color="primary" size={30} />
            </div>
          )}
        </form>
      </div>
    </Paper>
  );
};

export default DeliveryForm;
