import React, { useState, FormEvent } from "react";
import AddItemForm from "./addItemForm";
import Snackbar from "@material-ui/core/Snackbar";
import { GeneratorItem } from "../../../models/generatorItem";
import { postItem } from "../../../api/generator/items";

const initialState: GeneratorItem = {
  brand: "",
  capacity: "",
  model: "",
  phase: "",
  serialNumber: "",
  project: "",
  location: "",
  emaLicense: "",
  cost: 0,
  value: 0,
  deposit: 0,
  priceWeek: 0,
  priceMonth: 0,
  priceAnnual: 0,
};

const CreateItem = () => {
  const [state, updateState] = useState(initialState);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const updateForm = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    updateState({
      ...state,
      [name]: value,
    });
  };
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setSubmitLoading(true);
    postItem(state)
      .then((data) => {
        if (data) {
          setMessage("Successfully added item");
          setOpen(true);
          updateState(initialState);
          setSubmitLoading(false);
        } else {
          setMessage("Something went wrong, please try again");
          setOpen(true);
          setSubmitLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setMessage("Something went wrong, please try again");
        setOpen(true);
        setSubmitLoading(false);
      });
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={3000}
        message={message}
      />
      <AddItemForm
        item={state}
        submitLoading={submitLoading}
        updateForm={updateForm}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default CreateItem;
