import React from "react";
import Grid from "@material-ui/core/Grid";
import WithdrawItem from "../Equipment/WithdrawItem";
import Items from "../Equipment/Items";
import ReturnItem from "../Equipment/ReturnItem";
import CreateItem from "../Equipment/CreateItem";
import UpdateItem from "../Equipment/UpdateItem";
import DeleteItem from "../Equipment/DeleteItem";
import Transactions from "../Equipment/Transactions";
import Scan from "../Equipment/Scan";

const Equipment = (props: { eqButton: string }) => {
  const { eqButton } = props;
  return (
    <>
      <Grid item xs={12}>
        {eqButton === "scan" && <Scan />}
        {eqButton === "transactions" && <Transactions />}
        {eqButton === "withdraw" && <WithdrawItem />}
        {eqButton === "return" && <ReturnItem />}
        {eqButton === "add" && <CreateItem />}
        {eqButton === "edit" && <UpdateItem />}
        {eqButton === "remove" && <DeleteItem />}
        {eqButton === "equipment" && <Items />}
      </Grid>
    </>
  );
};

export default Equipment;
