import axios from "axios";
import moment from "moment-timezone";
import { InventoryTransaction } from "../../models/inventoryTransaction";

export const getTransactions = (): Promise<InventoryTransaction[]> => {
  return new Promise<InventoryTransaction[]>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/invt/transactions`,
      method: "GET",
      withCredentials: true,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        const transactions: InventoryTransaction[] = data.map((x: any) => {
          return {
            sn: x.sn,
            status: x.status,
            item: x.item,
            quantity: x.quantity,
            person: x.person,
            project: x.project,
            deliveryOrderId: x.delivery_order_id,
            timeStamp: moment
              .utc(x.time_stamp)
              .local()
              .format("DD/MM/YYYY, HH:mm:ss"),
          };
        });
        resolve(transactions);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getItemNames = (): Promise<{ sn: number; name: string }[]> => {
  return new Promise<{ sn: number; name: string }[]>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/invt/itemnames`,
      method: "GET",
      withCredentials: true,
    })
      .then((res) => res.data)
      .then((data) => {
        let snItemName = data.map((x: any) => {
          return {
            sn: x[0],
            name: x[1],
          };
        });
        console.log("API OK");
        resolve(snItemName);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
