import React, { useState, useEffect, FormEvent } from "react";
import Select from "react-select";
import { EquipmentItem } from "../../../models/equipmentItem";
import DeleteItemForm from "./deleteItemForm";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import { deleteItem } from "../../../api/equipment/items";
import {
  getItemNames,
  getItemSerialNumbers,
  getUniqueItem,
} from "../../../api/equipment/utils";

const DeleteItem = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSerial, setIsLoadingSerial] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [item, updateItemState] = useState(null as EquipmentItem | null);
  const [itemNames, setItemNames] = useState([{ label: "", value: "" }]);
  const [serialNumbers, setSerialNumbers] = useState(
    null as [{ label: ""; value: "" }] | null
  );
  const [selectedItem, setSelectedItem] = useState(
    null as { value: string; label: string } | null
  );
  const [selectedSerialNumber, setSelectedSerialNumber] = useState(
    null as { value: string; label: string } | null
  );
  const handleChange = (selectedOption: any, setFunction: Function) => {
    setFunction(selectedOption);
  };
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (item) {
      setSubmitLoading(true);
      deleteItem(item.itemName, item.serialNumber)
        .then((success) => {
          if (success) {
            setMessage("Successfully deleted item");
            setOpen(true);
            setSerialNumbers(null);
            updateItemState(null);
            setSubmitLoading(false);
          } else {
            setMessage("Something went wrong, please try again");
            setOpen(true);
            setSubmitLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setMessage("Something went wrong, please try again");
          setOpen(true);
          setSubmitLoading(false);
        });
    }
  };

  // Load item names
  useEffect(() => {
    getItemNames().then((itemNames: string[]) => {
      let itemNamesDict = itemNames.map((x: any) => ({
        value: x,
        label: x,
      }));
      setItemNames(itemNamesDict);
      setIsLoading(false);
    });
  }, []);

  // Load equipment serial numbers for the item
  useEffect(() => {
    if (selectedItem) {
      setIsLoadingSerial(true);
      setSerialNumbers(null);
      updateItemState(null);
      getItemSerialNumbers(selectedItem.value).then(
        (itemSerialNumbers: string[]) => {
          let serialNumbersDict: any = itemSerialNumbers
            .sort()
            .map((x: any) => ({ value: x, label: x }));
          setSerialNumbers(serialNumbersDict);
          setIsLoadingSerial(false);
        }
      );
    }
  }, [selectedItem]);

  useEffect(() => {
    if (selectedItem && selectedSerialNumber) {
      setIsLoadingForm(true);
      getUniqueItem(selectedItem.value, selectedSerialNumber.value).then(
        (item: EquipmentItem) => {
          updateItemState(item);
          setIsLoadingForm(false);
        }
      );
    }
    // eslint-disable-next-line
  }, [selectedSerialNumber]);

  return (
    <>
      {isLoading && (
        <div style={{ margin: 20, textAlign: "center" }}>
          <CircularProgress color="primary" />
        </div>
      )}
      {!isLoading && (
        <>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={open}
            onClose={() => setOpen(false)}
            autoHideDuration={3000}
            message={message}
          />
          <Paper style={{ padding: 10 }}>
            <div style={{ margin: 20 }}>
              <h2>Delete Item</h2>
              <span>Item to delete</span>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isLoading={isLoading}
                isClearable={true}
                isSearchable={true}
                name="color"
                options={itemNames}
                onChange={(selectedOption) =>
                  handleChange(selectedOption, setSelectedItem)
                }
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </div>
            {isLoadingSerial && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress color="primary" size={20} />
              </div>
            )}
            {serialNumbers && !isLoadingSerial && (
              <div style={{ margin: 20 }}>
                <span>Serial Number</span>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isLoading={isLoading}
                  isClearable={true}
                  isSearchable={true}
                  name="color"
                  options={serialNumbers}
                  onChange={(selectedOption) =>
                    handleChange(selectedOption, setSelectedSerialNumber)
                  }
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                />
              </div>
            )}
          </Paper>
          {isLoadingForm && (
            <div style={{ textAlign: "center", margin: 20 }}>
              <CircularProgress color="primary" size={30} />
            </div>
          )}
          {item && !isLoadingForm && (
            <DeleteItemForm
              item={item}
              submitLoading={submitLoading}
              handleSubmit={handleSubmit}
            />
          )}
        </>
      )}
    </>
  );
};

export default DeleteItem;
