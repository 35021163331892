import React, { FormEvent } from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, Theme } from "@material-ui/core/styles";

const WithdrawForm = (props: {
  loading: boolean;
  state: {
    sns: string[] | null;
    bundle: string;
    issueto: string
    project: string
    remarks: string;
  };
  submitForm: (event: FormEvent) => void;
  updateForm: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const classes = useStyles();
  const { loading, state, submitForm, updateForm } = props;
  return (
    <Container component="main" maxWidth="xs">
      <form onSubmit={submitForm} className={classes.paper}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="project"
          id="project"
          label="Project Name"
          value={state.project}
          onChange={updateForm}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="issueto"
          label="Name"
          id="issueto"
          value={state.issueto}
          onChange={updateForm}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="remarks"
          label="Remarks"
          id="remarks"
          value={state.remarks}
          onChange={updateForm}
        />
        {!loading && (
          <Button
            fullWidth
            disableElevation
            type="submit"
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            Withdraw Items
          </Button>
        )}
        {loading && (
          <CircularProgress
            size={30}
            color="primary"
            className={classes.submit}
          />
        )}
      </form>
    </Container>
  );
};

export default WithdrawForm;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(10),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontFamily: "Lato",
  },
}));
