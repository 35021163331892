import React from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { makeStyles, Theme } from "@material-ui/core/styles";

const SelectWithdrawReturnSlide = (props: {
  selectWithdrawReturn: (type: string) => void;
}) => {
  const classes = useStyles();
  const { selectWithdrawReturn } = props;
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Button
          fullWidth
          disableElevation
          className={classes.submit}
          variant="contained"
          color="secondary"
          onClick={() => selectWithdrawReturn("withdraw")}
          style={{ width: "70%" }}
        >
          WITHDRAW ITEMS
        </Button>
        <Button
          fullWidth
          disableElevation
          variant="contained"
          color="default"
          onClick={() => selectWithdrawReturn("return")}
          style={{ width: "70%" }}
        >
          RETURN ITEMS
        </Button>
      </div>
    </Container>
  );
};

export default SelectWithdrawReturnSlide;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(10),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontFamily: "Lato",
  },
}));
