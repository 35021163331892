import React, { useState, FormEvent } from "react";
import AddItemForm from "./addItemForm";
import Snackbar from "@material-ui/core/Snackbar";
import { createItem } from "../../../api/equipment/items";

const initialState = {
  itemName: null as string | null,
  category: null as string | null,
  bundle: null as string | null,
  imageUrl: null as string | null,
  model: null as string | null,
  serialNumber: null as string | null,
  remarks: null as string | null,
};

const CreateItem = () => {
  const [state, updateState] = useState(initialState);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const updateForm = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    updateState({
      ...state,
      [name]: value,
    });
  };
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setSubmitLoading(true);
    createItem(state)
      .then((success) => {
        if (success) {
          setMessage("Successfully added item");
          setOpen(true);
          updateState(initialState);
          setSubmitLoading(false);
        } else {
          setMessage("Something went wrong, please try again");
          setOpen(true);
          setSubmitLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setMessage("Something went wrong, please try again");
        setOpen(true);
        setSubmitLoading(false);
      });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={3000}
        message={message}
      />
      <AddItemForm
        state={state}
        submitLoading={submitLoading}
        updateForm={updateForm}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default CreateItem;
