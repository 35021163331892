// Adding and removing items requires admin access
export const eqAdminButtons = {
  scan: "Scan",
  withdraw: "Withdraw",
  return: "Return",
  add: "Add",
  remove: "Remove",
  edit: "Edit",
  equipment: "Equipment",
  transactions: "Transactions",
};
export const invtAdminButtons = {
  withdraw: "Withdraw",
  return: "Return",
  delivery: "Delivery",
  add: "Add",
  remove: "Remove",
  edit: "Edit",
  invt: "Inventory",
  transactions: "Transactions",
};
export const genAdminButtons = {
  withdraw: "Withdraw",
  return: "Return",
  add: "Add",
  remove: "Remove",
  edit: "Edit",
  gen: "Generators",
  transactions: "Transactions",
};
export const eqUserButtons = {
  scan: "Scan",
  withdraw: "Withdraw",
  return: "Return",
  equipment: "Equipment",
  transactions: "Transactions",
};
export const invtUserButtons = {
  withdraw: "Withdraw",
  return: "Return",
  delivery: "Delivery",
  invt: "Inventory",
  transactions: "Transactions",
};
export const genUserButtons = {
  withdraw: "Withdraw",
  return: "Return",
  gen: "Generators",
  transactions: "Transactions",
};
