import { AUTH_OK, AUTH_FORBIDDEN, AUTH_ERROR } from "../actions/types";

const INITIAL_STATE = {
  authenticated: false,
  accessToken: null,
  errorMessage: null,
  role: null,
  name: null,
  username: null,
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case AUTH_OK:
      return {
        ...state,
        authenticated: true,
        errorMessage: null,
        accessToken: action.payload.accessToken,
        role: action.payload.role,
        name: action.payload.name,
        username: action.payload.username,
      };
    case AUTH_FORBIDDEN:
      return {
        ...state,
        authenticated: false,
        errorMessage: action.payload,
        accessToken: null,
      };
    case AUTH_ERROR:
      return {
        ...state,
        authenticated: false,
        errorMessage: action.payload,
        accessToken: null,
      };
    default:
      return state;
  }
}
