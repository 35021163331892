import React, { FormEvent } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { InventoryItem } from "../../../models/inventoryItem";

const ReturnForm = (props: {
  item: InventoryItem;
  state: {
    project: string | null;
    person: string | null;
    quantity: number | null;
  };
  submitLoading: boolean;
  updateForm: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}) => {
  const { item, state, submitLoading, updateForm, handleSubmit } = props;
  return (
    <Paper style={{ padding: 10, marginTop: 20 }}>
      <div style={{ margin: 20 }}>
        <h2>Enter details to return {item.name}</h2>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Item Name"
            value={item.name || ""}
            autoFocus
            disabled
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="type"
            label="Item Type"
            value={item.category || ""}
            autoFocus
            disabled
          />
          <TextField
            name="project"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="project"
            label="Project"
            value={state.project || ""}
            onChange={updateForm}
            autoFocus
          />
          <TextField
            name="person"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="person"
            label="Issued By"
            value={state.person || ""}
            onChange={updateForm}
          />
          <TextField
            name="quantity"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="quantity"
            label="Quantity"
            type="number"
            value={state.quantity || ""}
            onChange={updateForm}
          />
          {!submitLoading && <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            style={{ marginTop: 20 }}
          >
            Return Item
          </Button>}
          {submitLoading && (
            <div style={{ textAlign: "center", margin: 20 }}>
              <CircularProgress color="primary" size={30} />
            </div>
          )}
        </form>
      </div>
    </Paper>
  );
};

export default ReturnForm;
