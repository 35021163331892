import React, { FormEvent } from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import CropFreeIcon from "@material-ui/icons/CropFree";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import Divider from "@material-ui/core/Divider";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { EquipmentItem } from "../../../models/equipmentItem";

interface IWithdrawReturnState {
  sns: string[] | null;
  bundle: string;
  issueto: string;
  project: string;
  remarks: string;
}

const ScanItemSlide = (props: {
  state: IWithdrawReturnState;
  itemsOnly: boolean;
  itemsInSet: EquipmentItem[] | null;
  showInput: boolean;
  setShowInput: React.Dispatch<React.SetStateAction<boolean>>;
  updateState: React.Dispatch<React.SetStateAction<IWithdrawReturnState>>;
  scanItem: (data: string) => void;
  scanItemWithoutSet: (data: string) => void;
  sn: string;
  setSn: React.Dispatch<React.SetStateAction<string>>;
  setOpenItemModal: React.Dispatch<React.SetStateAction<boolean>>;
  itemInOut: string;
  loading: boolean;
  submitForm: (event: FormEvent) => void;
  goToNext: () => void;
}) => {
  const classes = useStyles();
  const {
    state,
    itemsOnly,
    itemsInSet,
    showInput,
    setShowInput,
    updateState,
    scanItem,
    scanItemWithoutSet,
    sn,
    setSn,
    setOpenItemModal,
    itemInOut,
    loading,
    submitForm,
    goToNext,
  } = props;
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        {state.sns && state.sns.length > 0 && (
          <Box width="100%" mb={5}>
            <List dense={true}>
              {state.sns.map((x: string, idx: number) => {
                return (
                  <React.Fragment key={idx}>
                    <ListItem className={classes.listItem}>
                      <ListItemAvatar>
                        <BuildOutlinedIcon />
                      </ListItemAvatar>
                      <ListItemText
                        primary={`Item number: ${x}`}
                        secondary={`Name: ${
                          itemsInSet?.filter(
                            (y: any) => y.sn.toString() === x
                          )[0].itemName
                        }`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            let snArray = state.sns;
                            if (snArray) snArray.splice(idx, 1);
                            if (snArray && snArray.length === 0)
                              setShowInput(true);
                            updateState(
                              Object.assign({}, state, {
                                sns: snArray,
                              })
                            );
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </React.Fragment>
                );
              })}
            </List>
            {!showInput && (
              <div style={{ textAlign: "center" }}>
                <IconButton onClick={() => setShowInput(true)}>
                  <AddCircleOutlineOutlinedIcon color="primary" />
                </IconButton>
              </div>
            )}
          </Box>
        )}

        {showInput && (
          <Box width="100%">
            <form
              onSubmit={(event: FormEvent) => {
                event.preventDefault();
                itemsOnly ? scanItemWithoutSet(sn) : scanItem(sn);
              }}
            >
              <FormControl className={classes.form}>
                <InputLabel htmlFor="itemnumber-input">
                  SCAN OR TYPE ITEM NUMBER
                </InputLabel>
                <Input
                  required
                  id="itemnumber-input"
                  type="text"
                  onChange={(e) => {
                    setSn(e.target.value);
                  }}
                  value={sn}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        style={{ padding: 0, margin: 0 }}
                        onClick={() => {
                          setOpenItemModal(true);
                        }}
                      >
                        <CropFreeIcon color="secondary" />
                      </IconButton>
                    </InputAdornment>
                  }
                  endAdornment={
                    <IconButton type="submit">
                      <AddCircleOutlineOutlinedIcon color="primary" />
                    </IconButton>
                  }
                />
              </FormControl>
            </form>
          </Box>
        )}

        {state.sns && state.sns.length > 0 && itemInOut === "withdraw" && (
          <Button
            fullWidth
            disableElevation
            className={classes.submit}
            variant="contained"
            color="secondary"
            onClick={goToNext}
            style={{ marginTop: 30 }}
          >
            NEXT
          </Button>
        )}
        {itemInOut === "return" &&
          !loading &&
          state.sns &&
          state.sns.length > 0 && (
            <Button
              fullWidth
              disableElevation
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={submitForm}
            >
              Return Items
            </Button>
          )}
        {loading && (
          <CircularProgress
            size={30}
            color="primary"
            className={classes.submit}
          />
        )}
      </div>
    </Container>
  );
};

export default ScanItemSlide;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(10),
  },
  form: {
    display: "flex",
  },
  listItem: {
    "&.MuiListItem-gutters": {
      paddingLeft: 0,
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontFamily: "Lato",
  },
}));
