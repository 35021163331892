import axios from "axios";
import { InventoryItem } from "../../models/inventoryItem";

export const getItems = (): Promise<InventoryItem[]> => {
  return new Promise<InventoryItem[]>((resolve, reject) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/invt/items`,
      withCredentials: true,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        const items: InventoryItem[] = data.map((x: any) => {
          return {
            sn: x.sn,
            name: x.name,
            imageUrl: x.image_url,
            category: x.category,
            price: x.price,
            units: x.units,
            currentBalance: x.current_balance,
            optimalBalance: x.optimal_balance,
            location: x.location,
            remarks: x.remarks,
          };
        });
        resolve(items);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getItemById = (id: number): Promise<InventoryItem> => {
  return new Promise<InventoryItem>((resolve, reject) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/invt/items/${id}`,
      withCredentials: true,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        const item: InventoryItem = {
          sn: data.sn,
          name: data.name,
          imageUrl: data.image_url,
          category: data.category,
          price: data.price,
          units: data.units,
          currentBalance: data.current_balance,
          optimalBalance: data.optimal_balance,
          location: data.location,
          remarks: data.remarks,
        };
        resolve(item);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postItem = (item: InventoryItem): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/invt/items`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      data: item,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data.success);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateItem = (item: InventoryItem): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/invt/items`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      data: item,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data.success);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteItem = (sn: number): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/invt/items/${sn}`,
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data.success);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postItemWithdrawal = (
  name: string,
  project: string,
  person: string,
  quantity: number
): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/invt/storewithdrawals`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      data: {
        name,
        project,
        person,
        quantity,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data.success);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postItemReturn = (
  name: string,
  project: string,
  person: string,
  quantity: number
): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/invt/storereturns`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      data: {
        name,
        project,
        person,
        quantity,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data.success);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postItemDelivery = (
  name: string,
  deliveryOrderId: string,
  quantity: number
): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/invt/insertdeliveries`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      data: {
        name,
        deliveryOrderId,
        quantity,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data.success);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
