import React, { useState, FormEvent, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import logo from "../../assets/bb_logo_2.png";
import * as actions from "../../redux/actions/auth";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { connect, RootStateOrAny } from "react-redux";

interface StateProps {
  errorMessage: string;
  authenticated: boolean;
}

interface DispatchProps {
  loginWithUsernameAndPassword: (
    username: string,
    password: string
  ) => Promise<any>;
}

type Props = StateProps & DispatchProps;

const mapState = (state: RootStateOrAny) => ({
  errorMessage: state.auth.errorMessage,
  authenticated: state.auth.authenticated,
});

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://www.bengbeng.com.sg" target="_blank">
        Beng Beng Electrical
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const Login = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const { errorMessage, authenticated, loginWithUsernameAndPassword } = props;
  const classes = useStyles();
  const history = useHistory();
  const [state, updateState] = useState({
    username: null as string | null,
    password: null as string | null,
  });
  useEffect(() => {
    if (authenticated) {
      history.push("/dashboard");
    }
  }, [authenticated, errorMessage, history]);
  const handleClose = () => {
    setErrorOpen(false);
  };
  const login = (event: FormEvent) => {
    setLoading(true);
    event.preventDefault();
    loginWithUsernameAndPassword(
      state.username as string,
      state.password as string
    ).catch((err) => {
      setLoading(false);
      setErrorOpen(true);
    });
  };
  const updateForm = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    updateState({
      ...state,
      [name]: value,
    });
  };
  return (
    <>
      <Container component="main" maxWidth="xs">
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={errorOpen}
          onClose={handleClose}
          autoHideDuration={3000}
          message="Username or password wrong, please try again"
        />
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar} src={logo} />
          <Typography component="h1" variant="h6">
            Beng Beng Electrical
          </Typography>
          <form className={classes.form} onSubmit={login}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="username"
              id="username"
              label="Username"
              autoFocus
              onChange={updateForm}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={updateForm}
            />
            {loading && (
              <div style={{ margin: 20, textAlign: "center" }}>
                <CircularProgress color="primary" size={30} />
              </div>
            )}
            {!loading && (
              <>
                <Button
                  fullWidth
                  disableElevation
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
                >
                  Sign In
                </Button>
              </>
            )}
          </form>
        </div>
        <Box mt={4}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
};

export default connect(mapState, actions)(Login);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "Lato",
  },
  avatar: {
    margin: theme.spacing(1),
    border: "3px solid red",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontFamily: "Lato",
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
    fontFamily: "Lato",
  },
}));
