import axios from "axios";
import moment from "moment-timezone";
import { EquipmentItem } from "../../models/equipmentItem";
import { EquipmentTransaction } from "../../models/equipmentTransaction";

export const getUniqueItem = (
  itemname: string,
  serialnumber: string
): Promise<EquipmentItem> => {
  return new Promise<EquipmentItem>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/eq/selectunique`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      data: { itemname, serialnumber },
    })
      .then((res) => res.data)
      .then((x) => {
        console.log("API OK");
        let item: EquipmentItem = {
          sn: x.sn,
          status: x.status,
          category: x.category,
          bundle: x.bundle === null ? "-" : x.bundle,
          itemName: x.itemname,
          model: x.model,
          serialNumber: x.serialnumber,
          project: x.project,
          issueTo: x.issueto,
          returnBy: x.returnby,
          remarks: x.remarks,
          imageUrl: x.image_url,
          working: x.working,
          count: x.count,
        };
        resolve(item);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getItemNames = (): Promise<string[]> => {
  return new Promise<string[]>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/eq/itemnames`,
      method: "GET",
      withCredentials: true,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getItemSerialNumbers = (itemName: string): Promise<string[]> => {
  return new Promise<string[]>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/eq/serialnumber`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      data: { itemname: itemName },
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTransactions = (): Promise<EquipmentTransaction[]> => {
  return new Promise<EquipmentTransaction[]>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/eq/transactions`,
      method: "GET",
      withCredentials: true,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        const transactions: EquipmentTransaction[] = data.map((x: any) => {
          return {
            sn: x.sn,
            status: x.status,
            item: x.item,
            quantity: x.quantity,
            person: x.person,
            project: x.project,
            timeStamp: moment
              .utc(
                x.time_stamp.substr(-1) === "."
                  ? x.time_stamp.slice(0, -1)
                  : x.time_stamp
              )
              .local()
              .format("DD/MM/YYYY, HH:mm:ss"),
          };
        });
        resolve(transactions);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
