import React, { useEffect, useState } from "react";
import { getItems } from "../../api/generator/items";
import { GeneratorItem } from "../../models/generatorItem";
import {
  VictoryChart,
  VictoryBar,
  VictoryAxis,
  VictoryStack,
  VictoryLabel,
  VictoryLegend,
} from "victory";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Title from "./Title";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

export default function Generator() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState(null as GeneratorItem[] | null);
  const [itemsInWorkingCount, setItemsInWorkingCount] = useState(0);
  const [itemsInNotWorkingCount, setItemsInNotWorkingCount] = useState(0);
  const [itemsOutWorkingCount, setItemsOutWorkingCount] = useState(0);
  const [itemsOutNotWorkingCount, setItemsOutNotWorkingCount] = useState(0);
  const [itemsNotWorking, setItemsNotWorking] = useState(
    null as GeneratorItem[] | null
  );
  useEffect(() => {
    getItems().then((items: GeneratorItem[]) => {
      setItems(items);
    });
  }, []);
  useEffect(() => {
    console.log(items);
    if (items) {
      let inWorkingCount = items.filter(
        (x: any) => x.status === "in" && x.working === "Working"
      ).length;
      let inNotWorkingCount = items.filter(
        (x: any) => x.status === "in" && x.working === "Not Working"
      ).length;
      let outWorkingCount = items.filter(
        (x: any) => x.status === "out" && x.working === "Working"
      ).length;
      let outNotWorkingCount = items.filter(
        (x: any) => x.status === "out" && x.working === "Not Working"
      ).length;
      let itemsNotWorking = items.filter(
        (x: any) => x.working === "Not Working"
      );
      setItemsInWorkingCount(inWorkingCount);
      setItemsInNotWorkingCount(inNotWorkingCount);
      setItemsOutWorkingCount(outWorkingCount);
      setItemsOutNotWorkingCount(outNotWorkingCount);
      setItemsNotWorking(itemsNotWorking);
      setLoading(false);
    }
  }, [items]);
  return (
    <React.Fragment>
      <Title>Generators</Title>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {loading && (
            <div style={{ textAlign: "center", margin: 20 }}>
              <CircularProgress color="primary" size={30} />
            </div>
          )}
          {!loading && (
            <Paper className={classes.paper}>
              <VictoryChart width={300}>
                <VictoryAxis
                  tickValues={[1, 2]}
                  tickFormat={["Deployed", "Idle"]}
                />
                <VictoryLegend
                  orientation="vertical"
                  gutter={20}
                  colorScale={["tomato", "orange"]}
                  style={{
                    border: { stroke: "black" },
                    title: { fontSize: 20 },
                  }}
                  data={[{ name: "Working" }, { name: "Faulty" }]}
                />
                <VictoryStack
                  animate={{
                    duration: 500,
                    onLoad: { duration: 500 },
                  }}
                  colorScale={["tomato", "orange"]}
                >
                  <VictoryBar
                    labels={[itemsOutWorkingCount, itemsInWorkingCount]}
                    style={{
                      labels: { fill: "black" },
                      data: {
                        stroke: "#000000",
                        strokeWidth: 2,
                      },
                    }}
                    labelComponent={<VictoryLabel dy={20} />}
                    data={[
                      { x: "Deployed", y: itemsOutWorkingCount },
                      { x: "Idle", y: itemsInWorkingCount },
                    ]}
                    barRatio={0.7}
                  />
                  <VictoryBar
                    labels={[itemsOutNotWorkingCount, itemsInNotWorkingCount]}
                    style={{
                      labels: { fill: "black" },
                      data: {
                        stroke: "#000000",
                        strokeWidth: 2,
                      },
                    }}
                    labelComponent={<VictoryLabel dy={20} />}
                    data={[
                      { x: "Deployed", y: itemsOutNotWorkingCount },
                      { x: "Idle", y: itemsInNotWorkingCount },
                    ]}
                    barRatio={0.7}
                  />
                </VictoryStack>
              </VictoryChart>
            </Paper>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {loading && (
            <div style={{ textAlign: "center", margin: 20 }}>
              <CircularProgress color="primary" size={30} />
            </div>
          )}
          {!loading && (
            <Paper className={classes.paper}>
              <Title>Faulty machines</Title>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>S/N</TableCell>
                    <TableCell>Brand</TableCell>
                    <TableCell>Model</TableCell>
                    <TableCell>Serial Number</TableCell>
                    <TableCell align="right">Location</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemsNotWorking &&
                    itemsNotWorking.map((item: GeneratorItem) => (
                      <TableRow key={item.sn}>
                        <TableCell>{item.sn}</TableCell>
                        <TableCell>{item.brand}</TableCell>
                        <TableCell>{item.model}</TableCell>
                        <TableCell>{item.serialNumber}</TableCell>
                        <TableCell align="right">{item.location}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 400,
  },
}));
