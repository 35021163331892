import React from "react";
import Grid from "@material-ui/core/Grid";
import Items from "../Generator/Items";
import CreateItem from "../Generator/CreateItem";
import UpdateItem from "../Generator/UpdateItem";
import DeleteItem from "../Generator/DeleteItem";
import WithdrawItem from "../Generator/WithdrawItem";
import ReturnItem from "../Generator/ReturnItem";
import Transactions from "../Generator/Transactions";

const Generator = (props: { genButton: string }) => {
  const { genButton } = props;
  return (
    <>
      <Grid item xs={12}>
        {genButton === "transactions" && <Transactions />}
        {genButton === "withdraw" && <WithdrawItem />}
        {genButton === "return" && <ReturnItem />}
        {genButton === "add" && <CreateItem />}
        {genButton === "edit" && <UpdateItem />}
        {genButton === "remove" && <DeleteItem />}
        {genButton === "gen" && <Items />}
      </Grid>
    </>
  );
};

export default Generator;
