import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import './api/interceptor';
import "./assets/fonts/Lato-Regular.ttf";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0336FF",
    },
    secondary: {
      main: "#131200",
    },
  },
  typography: {
    fontFamily: [
      'Lato',
      'Roboto',
    ].join(','),
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
