import React, { Component } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { connect } from "react-redux";

interface PrivateRouteProps extends RouteProps {
  component: any;
  authenticated: boolean;
}

class PrivateRoute extends Component<PrivateRouteProps, {}> {
  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(routeProps) =>
          this.props.authenticated ? (
            <Component {...routeProps} />
          ) : (
            <>
              {alert("Login session expired")}
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: routeProps.location },
                }}
              />
            </>
          )
        }
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  authenticated: state.auth.authenticated,
});

export default connect(mapStateToProps, {})(PrivateRoute);
