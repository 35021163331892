import React, { useState, useEffect, forwardRef } from "react";
import MaterialTable from "material-table";
import { InventoryTransaction } from "../../../models/inventoryTransaction";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getTransactions } from "../../../api/inventory/utils";

const Transactions = () => {
  const [transactions, setTransactions] = useState(
    null as InventoryTransaction[] | null
  );
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getTransactions().then((transactions: InventoryTransaction[]) => {
      setTransactions(transactions.reverse());
      setIsLoading(false);
    });
  }, []);

  return (
    <div style={{ maxWidth: "100%", margin: "auto 0", textAlign: "center" }}>
      {isLoading && (
        <div style={{ margin: 20 }}>
          <CircularProgress color="primary" />
        </div>
      )}
      {transactions && !isLoading && (
        <MaterialTable
          icons={tableIcons}
          columns={[
            { title: "S/N", field: "sn", type: "numeric" },
            { title: "Type", field: "status", type: "string" },
            { title: "Item", field: "item", type: "string" },
            { title: "Quantity", field: "quantity", type: "numeric" },
            { title: "Person", field: "person", type: "string" },
            { title: "Project", field: "project", type: "string" },
            { title: "D/O", field: "deliveryOrderId", type: "string" },
            { title: "Timestamp", field: "timeStamp", type: "datetime" },
          ]}
          data={transactions as any}
          title="Beng Beng Electronics Inventory Transactions"
          options={{
            pageSize: 20,
            exportButton: true,
            exportAllData: true,
            rowStyle: (rowData, idx) => ({
              backgroundColor: idx % 2 === 0 ? "#F5F5F5" : "white",
            }),
          }}
        />
      )}
    </div>
  );
};

const tableIcons = {
  Add: forwardRef((props: any, ref: any) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props: any, ref: any) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props: any, ref: any) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props: any, ref: any) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props: any, ref: any) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props: any, ref: any) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props: any, ref: any) => (
    <SaveAlt {...props} ref={ref} color="primary" />
  )),
  Filter: forwardRef((props: any, ref: any) => (
    <FilterList {...props} ref={ref} />
  )),
  FirstPage: forwardRef((props: any, ref: any) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: forwardRef((props: any, ref: any) => (
    <LastPage {...props} ref={ref} />
  )),
  NextPage: forwardRef((props: any, ref: any) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: forwardRef((props: any, ref: any) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props: any, ref: any) => (
    <Clear {...props} ref={ref} />
  )),
  Search: forwardRef((props: any, ref: any) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props: any, ref: any) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props: any, ref: any) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props: any, ref: any) => (
    <ViewColumn {...props} ref={ref} />
  )),
};

export default Transactions;
