import axios from "axios";

export const getItemsIn = (): Promise<string[]> => {
  return new Promise<string[]>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/eq/itemsin`,
      method: "GET",
      withCredentials: true,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSerialNumbersIn = (itemName: string): Promise<string[]> => {
  return new Promise<string[]>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/eq/serialnumberin`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: { itemname: itemName },
      withCredentials: true,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postItemReturn = (
  itemname: string,
  serialnumber: string,
  project: string,
  issueto: string,
  remarks: string,
  condition: string
): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/eq/storereturns`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      data: {
        itemname,
        serialnumber,
        project,
        issueto,
        remarks,
        condition,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        resolve(data.success);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
