import React, { useState, useEffect, forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import CircularProgress from "@material-ui/core/CircularProgress";
import { EquipmentItem } from "../../../models/equipmentItem";
import { getItems } from "../../../api/equipment/items";

const Items = () => {
  const [items, setItems] = useState(null as EquipmentItem[] | null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getItems().then((items: EquipmentItem[]) => {
      setItems(items);
      setIsLoading(false);
    });
  }, []);
  return (
    <div style={{ maxWidth: "100%", margin: "auto 0", textAlign: "center" }}>
      {isLoading && (
        <div style={{ margin: 20 }}>
          <CircularProgress color="primary" />
        </div>
      )}
      {items && !isLoading && (
        <MaterialTable
          icons={tableIcons}
          columns={[
            { title: "QR Code", field: "sn", type: "numeric" },
            { title: "Type", field: "category", type: "string" },
            { title: "Set", field: "bundle", type: "string" },
            { title: "Item Name", field: "itemName", type: "string" },
            { title: "Model", field: "model", type: "string" },
            { title: "Serial Number", field: "serialNumber", type: "string" },
            { title: "Status", field: "status", type: "string" },
            { title: "Project", field: "project", type: "string" },
            { title: "Issue To", field: "issueTo", type: "string" },
            { title: "Time", field: "returnBy", type: "datetime" },
            { title: "Remarks", field: "remarks", type: "string" },
            { title: "Condition", field: "working", type: "string" },
          ]}
          data={items as any}
          title="Beng Beng Electronics Equipment"
          options={{
            pageSize: 20,
            exportButton: true,
            exportAllData: true,
            rowStyle: (rowData, idx) => ({
              backgroundColor: idx % 2 === 0 ? "#F5F5F5" : "white",
            }),
          }}
        />
      )}
    </div>
  );
};

const tableIcons = {
  Add: forwardRef((props: any, ref: any) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props: any, ref: any) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props: any, ref: any) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props: any, ref: any) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props: any, ref: any) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props: any, ref: any) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props: any, ref: any) => (
    <SaveAlt {...props} ref={ref} color="primary" />
  )),
  Filter: forwardRef((props: any, ref: any) => (
    <FilterList {...props} ref={ref} />
  )),
  FirstPage: forwardRef((props: any, ref: any) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: forwardRef((props: any, ref: any) => (
    <LastPage {...props} ref={ref} />
  )),
  NextPage: forwardRef((props: any, ref: any) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: forwardRef((props: any, ref: any) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props: any, ref: any) => (
    <Clear {...props} ref={ref} />
  )),
  Search: forwardRef((props: any, ref: any) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props: any, ref: any) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props: any, ref: any) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props: any, ref: any) => (
    <ViewColumn {...props} ref={ref} />
  )),
};

export default Items;
