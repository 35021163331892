import React, { FormEvent, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import DateFnsUtils from "@date-io/date-fns";
import { GeneratorItem } from "../../../models/generatorItem";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const UpdateItemForm = (props: {
  item: GeneratorItem;
  submitLoading: boolean;
  updateForm: (
    event:
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | { target: { name: string; value: string } }
  ) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}) => {
  const { item, submitLoading, updateForm, handleSubmit } = props;
  const [workingButton, setWorkingButton] = useState(item.working ? 1 : 0);
  const handleToggle = (e: any, newButton: string, setFunction: Function) => {
    if (newButton !== null) setFunction(newButton);
  };
  const handleDateChange = (name: string, date: Date | null) => {
    if (date && date.toJSON()) {
      let formattedDate = date.toJSON().slice(0, 10);
      console.log(formattedDate);
      updateForm({ target: { name: name, value: formattedDate } });
    }
  };
  return (
    <Paper style={{ padding: 10, marginTop: 20 }}>
      <div style={{ margin: 20 }}>
        <form onSubmit={handleSubmit}>
          <TextField
            name="brand"
            variant="outlined"
            margin="normal"
            id="brand"
            label="Brand"
            value={item.brand || ""}
            onChange={updateForm}
            type="text"
            required
            fullWidth
          />
          <TextField
            name="capacity"
            variant="outlined"
            margin="normal"
            id="capacity"
            label="Capacity"
            value={item.capacity || ""}
            onChange={updateForm}
            type="text"
            required
            fullWidth
          />
          <TextField
            name="model"
            variant="outlined"
            margin="normal"
            id="model"
            label="Model"
            value={item.model || ""}
            onChange={updateForm}
            type="text"
            required
            fullWidth
          />
          <TextField
            name="phase"
            variant="outlined"
            margin="normal"
            id="phase"
            label="Phase"
            value={item.phase || ""}
            onChange={updateForm}
            type="text"
            fullWidth
          />
          <TextField
            name="serialNumber"
            variant="outlined"
            margin="normal"
            id="serialNumber"
            label="Serial Number"
            value={item.serialNumber || ""}
            onChange={updateForm}
            type="text"
            required
            fullWidth
          />
          <TextField
            name="project"
            variant="outlined"
            margin="normal"
            id="project"
            label="Project"
            value={item.project || ""}
            onChange={updateForm}
            type="text"
            fullWidth
          />
          <TextField
            name="location"
            variant="outlined"
            margin="normal"
            id="location"
            label="Location"
            value={item.location || ""}
            onChange={updateForm}
            type="text"
            fullWidth
          />
          <TextField
            name="emaLicense"
            variant="outlined"
            margin="normal"
            id="emaLicense"
            label="EMA License"
            value={item.emaLicense || ""}
            onChange={updateForm}
            type="text"
            fullWidth
          />
          <TextField
            name="cost"
            variant="outlined"
            margin="normal"
            id="cost"
            label="Cost"
            value={item.cost || ""}
            onChange={updateForm}
            type="number"
            inputProps={{ step: "any" }}
            fullWidth
          />
          <TextField
            name="value"
            variant="outlined"
            margin="normal"
            id="value"
            label="Value"
            value={item.value || ""}
            onChange={updateForm}
            type="number"
            inputProps={{ step: "any" }}
            fullWidth
          />
          <TextField
            name="deposit"
            variant="outlined"
            margin="normal"
            id="deposit"
            label="Deposit"
            value={item.deposit || ""}
            onChange={updateForm}
            type="number"
            inputProps={{ step: "any" }}
            fullWidth
          />
          <TextField
            name="priceWeek"
            variant="outlined"
            margin="normal"
            id="priceWeek"
            label="Weekly Price"
            value={item.priceWeek || ""}
            onChange={updateForm}
            type="number"
            inputProps={{ step: "any" }}
            fullWidth
          />
          <TextField
            name="priceMonth"
            variant="outlined"
            margin="normal"
            id="priceMonth"
            label="Monthly Price"
            value={item.priceMonth || ""}
            onChange={updateForm}
            type="number"
            inputProps={{ step: "any" }}
            fullWidth
          />
          <TextField
            name="priceAnnual"
            variant="outlined"
            margin="normal"
            id="priceAnnual"
            label="Annual Price"
            value={item.priceAnnual || ""}
            onChange={updateForm}
            type="number"
            inputProps={{ step: "any" }}
            fullWidth
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              id="startDate"
              label="Start Date"
              name="startDate"
              value={item.startDate}
              onChange={(date: Date | null) =>
                handleDateChange("startDate", date)
              }
              KeyboardButtonProps={{
                "aria-label": "start date",
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              id="endDate"
              label="End Date"
              name="endDate"
              value={item.endDate}
              onChange={(date: Date | null) =>
                handleDateChange("endDate", date)
              }
              KeyboardButtonProps={{
                "aria-label": "end date",
              }}
            />
          </MuiPickersUtilsProvider>
          <TextField
            name="status"
            variant="outlined"
            margin="normal"
            id="status"
            label="Status"
            value={item.status || ""}
            onChange={updateForm}
            type="date"
            fullWidth
          />
          <div style={{ textAlign: "center" }}>
            <ToggleButtonGroup
              onChange={(event: any, value: string) => {
                handleToggle(event, value, setWorkingButton);
                updateForm({
                  target: {
                    name: "working",
                    value: value,
                  },
                });
              }}
              value={workingButton}
              exclusive
              aria-label="text alignment"
            >
              <ToggleButton
                size="medium"
                value={1}
                onClick={() => setWorkingButton(1)}
              >
                Working
              </ToggleButton>
              <ToggleButton
                size="medium"
                value={0}
                onClick={() => setWorkingButton(0)}
              >
                Not Working
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          {!submitLoading && (
            <Button
              fullWidth
              disableElevation
              type="submit"
              variant="contained"
              color="secondary"
              style={{ marginTop: 20 }}
            >
              Edit Item
            </Button>
          )}
          {submitLoading && (
            <div style={{ textAlign: "center", margin: 20 }}>
              <CircularProgress color="primary" size={30} />
            </div>
          )}
        </form>
      </div>
    </Paper>
  );
};

export default UpdateItemForm;
