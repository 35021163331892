import React, { FormEvent } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { GeneratorItem } from "../../../models/generatorItem";

const AddItemForm = (props: {
  item: GeneratorItem;
  submitLoading: boolean;
  updateForm: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}) => {
  const { item, submitLoading, updateForm, handleSubmit } = props;
  return (
    <Paper style={{ padding: 10 }}>
      <div style={{ margin: 20 }}>
        <h2>Add Generator </h2>
        <form onSubmit={handleSubmit}>
          <TextField
            name="brand"
            variant="outlined"
            margin="normal"
            id="brand"
            label="Brand"
            value={item.brand || ""}
            onChange={updateForm}
            type="text"
            required
            fullWidth
          />
          <TextField
            name="capacity"
            variant="outlined"
            margin="normal"
            id="capacity"
            label="Capacity"
            value={item.capacity || ""}
            onChange={updateForm}
            type="text"
            required
            fullWidth
          />
          <TextField
            name="model"
            variant="outlined"
            margin="normal"
            id="model"
            label="Model"
            value={item.model || ""}
            onChange={updateForm}
            type="text"
            required
            fullWidth
          />
          <TextField
            name="phase"
            variant="outlined"
            margin="normal"
            id="phase"
            label="Phase"
            value={item.phase || ""}
            onChange={updateForm}
            type="text"
            required
            fullWidth
          />
          <TextField
            name="serialNumber"
            variant="outlined"
            margin="normal"
            id="serialNumber"
            label="Serial Number"
            value={item.serialNumber || ""}
            onChange={updateForm}
            type="text"
            required
            fullWidth
          />
          <TextField
            name="project"
            variant="outlined"
            margin="normal"
            id="project"
            label="Project"
            value={item.project || ""}
            onChange={updateForm}
            type="text"
            required
            fullWidth
          />
          <TextField
            name="location"
            variant="outlined"
            margin="normal"
            id="location"
            label="Location"
            value={item.location || ""}
            onChange={updateForm}
            type="text"
            required
            fullWidth
          />
          <TextField
            name="emaLicense"
            variant="outlined"
            margin="normal"
            id="emaLicense"
            label="EMA License"
            value={item.emaLicense || ""}
            onChange={updateForm}
            type="text"
            required
            fullWidth
          />
          <TextField
            name="cost"
            variant="outlined"
            margin="normal"
            id="cost"
            label="Cost"
            value={item.cost || ""}
            onChange={updateForm}
            type="number"
            inputProps={{ step: "any" }}
            required
            fullWidth
          />
          <TextField
            name="value"
            variant="outlined"
            margin="normal"
            id="value"
            label="Value"
            value={item.value || ""}
            onChange={updateForm}
            type="number"
            inputProps={{ step: "any" }}
            required
            fullWidth
          />
          <TextField
            name="deposit"
            variant="outlined"
            margin="normal"
            id="deposit"
            label="Deposit"
            value={item.deposit || ""}
            onChange={updateForm}
            type="number"
            inputProps={{ step: "any" }}
            required
            fullWidth
          />
          <TextField
            name="priceWeek"
            variant="outlined"
            margin="normal"
            id="priceWeek"
            label="Weekly Price"
            value={item.priceWeek || ""}
            onChange={updateForm}
            type="number"
            inputProps={{ step: "any" }}
            required
            fullWidth
          />
          <TextField
            name="priceMonth"
            variant="outlined"
            margin="normal"
            id="priceMonth"
            label="Monthly Price"
            value={item.priceMonth || ""}
            onChange={updateForm}
            type="number"
            inputProps={{ step: "any" }}
            required
            fullWidth
          />
          <TextField
            name="priceAnnual"
            variant="outlined"
            margin="normal"
            id="priceAnnual"
            label="Annual Price"
            value={item.priceAnnual || ""}
            onChange={updateForm}
            type="number"
            inputProps={{ step: "any" }}
            required
            fullWidth
          />
          {!submitLoading && (
            <Button
              fullWidth
              disableElevation
              type="submit"
              variant="contained"
              color="secondary"
              style={{ marginTop: 20 }}
            >
              Add Item
            </Button>
          )}
          {submitLoading && (
            <div style={{ textAlign: "center", margin: 20 }}>
              <CircularProgress color="primary" size={30} />
            </div>
          )}
        </form>
      </div>
    </Paper>
  );
};

export default AddItemForm;
