import React, { useState, useEffect, FormEvent } from "react";
import Select from "react-select";
import ReturnForm from "./returnForm";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { InventoryItem } from "../../../models/inventoryItem";
import { getItemById, postItemReturn } from "../../../api/inventory/items";
import { getItemNames } from "../../../api/inventory/utils";

const ReturnItem = () => {
  const [item, setItem] = useState(null as InventoryItem | null);
  const [items, setItems] = useState([{ label: "", value: 0 }]);
  const [selectedItem, setSelectedItem] = useState(
    null as { value: number; label: string } | null
  );
  const [isLoading, setIsLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [state, updateState] = useState({
    project: null as string | null,
    person: null as string | null,
    quantity: null as number | null,
  });
  const updateForm = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    updateState({
      ...state,
      [name]: value,
    });
  };
  const handleChange = (selectedOption: any, setFunction: Function) => {
    setFunction(selectedOption);
  };
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (item) {
      setSubmitLoading(true);
      postItemReturn(
        item.name,
        state.project as string,
        state.person as string,
        state.quantity as number
      )
        .then((success) => {
          if (success) {
            setMessage("Item has been successfully returned");
            setOpen(true);
            setItem(null);
            updateState({ project: null, person: null, quantity: null });
            setSubmitLoading(false);
          } else {
            setMessage("Something went wrong, please try again");
            setOpen(true);
            setSubmitLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setMessage("Something went wrong, please try again");
          setOpen(true);
          setSubmitLoading(false);
        });
    }
  };

  // Load item names
  useEffect(() => {
    getItemNames().then((snItemNames: { sn: number; name: string }[]) => {
      let itemNamesDict = snItemNames.map(
        (x: { sn: number; name: string }) => ({
          value: x.sn,
          label: x.name,
        })
      );
      setItems(itemNamesDict);
      setIsLoading(false);
    });
  }, []);

  // Load item data
  useEffect(() => {
    if (selectedItem) {
      setIsLoadingForm(true);
      getItemById(selectedItem.value).then((item: InventoryItem) => {
        setItem(item);
        setIsLoadingForm(false);
      });
    }
    // eslint-disable-next-line
  }, [selectedItem]);

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={3000}
        message={message}
      />
      {isLoading && (
        <div style={{ margin: 20, textAlign: "center" }}>
          <CircularProgress color="primary" />
        </div>
      )}
      {!isLoading && (
        <>
          <Paper style={{ padding: 10 }}>
            <div style={{ margin: 20 }}>
              <h2>Inventory Returns</h2>
              <span>Item to return</span>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isLoading={isLoading}
                isClearable={true}
                isSearchable={true}
                name="color"
                options={items}
                onChange={(selectedOption) =>
                  handleChange(selectedOption, setSelectedItem)
                }
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </div>
          </Paper>
          {isLoadingForm && (
            <div style={{ textAlign: "center", margin: 20 }}>
              <CircularProgress color="primary" size={30} />
            </div>
          )}
          {item && !isLoadingForm && (
            <>
              <ReturnForm
                item={item}
                state={state}
                submitLoading={submitLoading}
                updateForm={updateForm}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default ReturnItem;
