import React, { useState, FormEvent } from "react";
import AddItemForm from "./addItemForm";
import Snackbar from "@material-ui/core/Snackbar";
import { InventoryItem } from "../../../models/inventoryItem";
import { postItem } from "../../../api/inventory/items";

const initialState: InventoryItem = {
  name: "",
  imageUrl: "",
  category: "",
  price: 0,
  units: "",
  currentBalance: 0,
  optimalBalance: 0,
  location: "",
  remarks: "",
};

const CreateItem = () => {
  const [state, updateState] = useState(initialState);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const updateForm = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    updateState({
      ...state,
      [name]: value,
    });
  };
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setSubmitLoading(true);
    postItem(state)
      .then((data) => {
        if (data) {
          setMessage("Successfully added item");
          setOpen(true);
          updateState(initialState);
          setSubmitLoading(false);
        } else {
          setMessage("Something went wrong, please try again");
          setOpen(true);
          setSubmitLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setMessage("Something went wrong, please try again");
        setOpen(true);
        setSubmitLoading(false);
      });
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={3000}
        message={message}
      />
      <AddItemForm
        item={state}
        submitLoading={submitLoading}
        updateForm={updateForm}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default CreateItem;
