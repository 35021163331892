import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import { isExpired } from "react-jwt";
import {
  eqAdminButtons,
  eqUserButtons,
  invtAdminButtons,
  invtUserButtons,
  genAdminButtons,
  genUserButtons,
} from "../../config/constants";
import Link from "@material-ui/core/Link";
import * as actions from "../../redux/actions/auth";
import Equipment from "./Equipment";
import Inventory from "./Inventory";
import Generator from "./Generator";
import { store } from "../../redux/store";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import DrawerList from "./DrawerList";
import { AUTH_FORBIDDEN } from "../../redux/actions/types";
import Home from "../Home";

const drawerWidth = 220;

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://www.bengbeng.com.sg" target="_blank">
        Beng Beng Electrical
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

interface DispatchProps {
  logout: () => void;
}

type Props = DispatchProps;

const Dashboard = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [eqButton, setEqButton] = useState("");
  const [invtButton, setInvtButton] = useState("");
  const [genButton, setGenButton] = useState("");
  const [selectedDashboard, setSelectedDashboard] = useState("HOME");
  const [role, setRole] = useState(null);
  const [eqButtons, setEqButtons] = useState({} as { [key: string]: string });
  const [invtButtons, setInvtButtons] = useState(
    {} as { [key: string]: string }
  );
  const [genButtons, setGenButtons] = useState({} as { [key: string]: string });
  const [eqOpen, setEqOpen] = React.useState(false);
  const [invtOpen, setInvtOpen] = React.useState(false);
  const [genOpen, setGenOpen] = React.useState(false);

  const selectDashboard = (dashboard: string) => {
    setSelectedDashboard(dashboard);
    if (mobileOpen) handleDrawerToggle();
  };

  useEffect(() => {
    const authState = store.getState().auth;
    setRole(authState.role);
    let jwtExpired = isExpired(authState.accessToken);
    if (jwtExpired)
      store.dispatch({ type: AUTH_FORBIDDEN, payload: "Unauthorised" });
  }, []);

  useEffect(() => {
    if (role === "admin") {
      setEqButtons(eqAdminButtons);
      setInvtButtons(invtAdminButtons);
      setGenButtons(genAdminButtons);
    } else {
      setEqButtons(eqUserButtons);
      setInvtButtons(invtUserButtons);
      setGenButtons(genUserButtons);
    }
  }, [role]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logout = () => {
    props.logout();
    history.push("/");
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List disablePadding>
        <DrawerList
          eqOpen={eqOpen}
          invtOpen={invtOpen}
          genOpen={genOpen}
          setEqOpen={setEqOpen}
          setInvtOpen={setInvtOpen}
          setGenOpen={setGenOpen}
          selectedDashboard={selectedDashboard}
          selectDashboard={selectDashboard}
          eqButton={eqButton}
          eqButtons={eqButtons}
          setEqButton={setEqButton}
          invtButton={invtButton}
          invtButtons={invtButtons}
          setInvtButton={setInvtButton}
          genButton={genButton}
          genButtons={genButtons}
          setGenButton={setGenButton}
        />
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={classes.appBar}
        color={"inherit"}
        elevation={1}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {selectedDashboard}
          </Typography>
          <IconButton color="inherit" onClick={logout}>
            <PowerSettingsNewOutlinedIcon color="primary" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {selectedDashboard === "HOME" && (
              <Home />
            )}
            {selectedDashboard === "EQUIPMENT" && (
              <Equipment eqButton={eqButton} />
            )}
            {selectedDashboard === "INVENTORY" && (
              <Inventory invtButton={invtButton} />
            )}
            {selectedDashboard === "GENERATOR" && (
              <Generator genButton={genButton} />
            )}
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
};

export default connect(null, actions)(Dashboard);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    listItem: {
      "&.Mui-selected": {
        backgroundColor: "blue",
        color: "white",
      },
      "&.Mui-selected:hover": {
        backgroundColor: "blue",
        color: "white",
      },
      "&:hover": {
        backgroundColor: "blue",
        color: "white",
      },
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    title: {
      flexGrow: 1,
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  })
);
