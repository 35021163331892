import axios from "axios";
import { EquipmentItem } from "../../models/equipmentItem";

export const getItemsFromBundle = (bundle: string) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/eq/items/bundle/${bundle}`,
      method: "GET",
    })
      .then((res) => res.data)
      .then((data) => {
        if (data === "No items in the set") {
          resolve("No items in the set");
        } else {
          const items: EquipmentItem[] = data.map((x: any) => {
            return {
              sn: x.sn,
              status: x.status,
              category: x.category,
              bundle: x.bundle === null ? "-" : x.bundle,
              itemName: x.itemname,
              model: x.model,
              serialNumber: x.serialnumber,
              project: x.project,
              issueTo: x.issueto,
              returnBy: x.returnby,
              remarks: x.remarks,
              imageUrl: x.image_url,
              working: x.working === "true" ? "Working" : "Not working",
              count: x.count,
            };
          });
          resolve(items);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const scanWithdrawItems = (
  sns: string[] | null,
  issueto: string,
  project: string,
  remarks: string
) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/eq/items/scanwithdraw`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
      data: {
        sns,
        issueto,
        project,
        remarks,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        resolve(data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const scanReturnItems = (sns: string[] | null) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/eq/items/scanreturn`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        sns,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        resolve(data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};
