import axios from "axios";
import moment from "moment-timezone";
import { GeneratorTransaction } from "../../models/generatorTransaction";

export const getBrands = (): Promise<string[]> => {
  return new Promise<string[]>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/generators/items/brands`,
      method: "GET",
      withCredentials: true,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getBrandsIn = (): Promise<string[]> => {
  return new Promise<string[]>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/generators/items/brands/in`,
      method: "GET",
      withCredentials: true,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getBrandsOut = (): Promise<string[]> => {
  return new Promise<string[]>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/generators/items/brands/out`,
      method: "GET",
      withCredentials: true,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSerialNumbers = (
  brand: string
): Promise<{ sn: number; serialNumber: string }[]> => {
  return new Promise<{ sn: number; serialNumber: string }[]>(
    (resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_API_BASE_URL}/generators/items/brands/${brand}/serialnumbers`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      })
        .then((res) => res.data)
        .then((data) => {
          console.log("API OK");
          let snSerialNumbers = data.map((x: any) => {
            return {
              sn: x[0],
              serialNumber: x[1],
            };
          });
          resolve(snSerialNumbers);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
};

export const getSerialNumbersIn = (
  brand: string
): Promise<{ sn: number; serialNumber: string }[]> => {
  return new Promise<{ sn: number; serialNumber: string }[]>(
    (resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_API_BASE_URL}/generators/items/brands/${brand}/serialnumbers`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      })
        .then((res) => res.data)
        .then((data) => {
          console.log("API OK");
          let snSerialNumbers = data
            .filter((x: any) => x[2] === "in")
            .map((x: any) => {
              return {
                sn: x[0],
                serialNumber: x[1],
              };
            });
          resolve(snSerialNumbers);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
};

export const getSerialNumbersOut = (
  brand: string
): Promise<{ sn: number; serialNumber: string }[]> => {
  return new Promise<{ sn: number; serialNumber: string }[]>(
    (resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_API_BASE_URL}/generators/items/brands/${brand}/serialnumbers`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      })
        .then((res) => res.data)
        .then((data) => {
          console.log("API OK");
          let snSerialNumbers = data
            .filter((x: any) => x[2] === "out")
            .map((x: any) => {
              return {
                sn: x[0],
                serialNumber: x[1],
              };
            });
          resolve(snSerialNumbers);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
};

export const getTransactions = (): Promise<GeneratorTransaction[]> => {
  return new Promise<GeneratorTransaction[]>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/generators/transactions`,
      method: "GET",
      withCredentials: true,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        const transactions: GeneratorTransaction[] = data.map((x: any) => {
          return {
            sn: x.sn,
            brand: x.brand,
            itemSn: x.item_sn,
            serialNumber: x.serial_number,
            status: x.status,
            startDate: x.start_date,
            endDate: x.end_date,
            location: x.location,
            project: x.project,
            timeStamp: moment
              .utc(x.time_stamp)
              .local()
              .format("DD/MM/YYYY, HH:mm:ss"),
          };
        });
        resolve(transactions);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
