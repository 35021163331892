import React, { useState, useEffect, FormEvent } from "react";
import Select from "react-select";
import DeleteItemForm from "./deleteItemForm";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { InventoryItem } from "../../../models/inventoryItem";
import { getItemById, deleteItem } from "../../../api/inventory/items";
import { getItemNames } from "../../../api/inventory/utils";

const DeleteItem = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [item, updateItemState] = useState(null as InventoryItem | null);
  const [itemNames, setItemNames] = useState([{ label: "", value: 0 }]);
  const [selectedItem, setSelectedItem] = useState(
    null as { value: number; label: string } | null
  );
  const handleChange = (selectedOption: any, setFunction: Function) => {
    setFunction(selectedOption);
  };
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setSubmitLoading(true);
    if (item) {
      deleteItem(item.sn as number)
        .then((success) => {
          if (success) {
            setMessage("Successfully deleted item");
            setOpen(true);
            updateItemState(null);
            setSubmitLoading(false);
          } else {
            setMessage("Something went wrong, please try again");
            setOpen(true);
            setSubmitLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setMessage("Something went wrong, please try again");
          setOpen(true);
          setSubmitLoading(false);
        });
    }
  };

  // Load item names
  useEffect(() => {
    getItemNames().then((snItemNames: { sn: number; name: string }[]) => {
      let itemNamesDict = snItemNames.map(
        (x: { sn: number; name: string }) => ({
          value: x.sn,
          label: x.name,
        })
      );
      setItemNames(itemNamesDict);
      setIsLoading(false);
    });
  }, []);

  // Select item name
  useEffect(() => {
    if (selectedItem) {
      setIsLoadingForm(true);
      getItemById(selectedItem.value).then((item: InventoryItem) => {
        updateItemState(item);
        setIsLoadingForm(false);
      });
    }
  }, [selectedItem]);

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={3000}
        message={message}
      />
      {isLoading && (
        <div style={{ margin: 20, textAlign: "center" }}>
          <CircularProgress color="primary" />
        </div>
      )}
      {!isLoading && (
        <>
          <Paper style={{ padding: 10 }}>
            <div style={{ margin: 20 }}>
              <h2>Delete Item</h2>
              <span>Item to delete</span>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isLoading={isLoading}
                isClearable={true}
                isSearchable={true}
                name="color"
                options={itemNames}
                onChange={(selectedOption) =>
                  handleChange(selectedOption, setSelectedItem)
                }
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </div>
          </Paper>
          {isLoadingForm && (
            <div style={{ textAlign: "center", margin: 20 }}>
              <CircularProgress color="primary" size={30} />
            </div>
          )}
          {item && !isLoadingForm && (
            <DeleteItemForm
              item={item}
              submitLoading={submitLoading}
              handleSubmit={handleSubmit}
            />
          )}
        </>
      )}
    </>
  );
};

export default DeleteItem;
