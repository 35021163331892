import React, { useState, useEffect, FormEvent } from "react";
import Select from "react-select";
import ReturnForm from "./returnForm";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import { postItemReturn } from "../../../api/equipment/itemsIn";
import { EquipmentItem } from "../../../models/equipmentItem";
import {
  getItemsOut,
  getSerialNumbersOut,
} from "../../../api/equipment/itemsOut";
import { getUniqueItem } from "../../../api/equipment/utils";

const ReturnItem = () => {
  const [item, setItem] = useState(null as EquipmentItem | null);
  const [items, setItems] = useState([{ label: "", value: "" }]);
  const [selectedItem, setSelectedItem] = useState(
    null as { value: string; label: string } | null
  );
  const [serialNumbers, setSerialNumbers] = useState(
    null as [{ label: ""; value: "" }] | null
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSerial, setIsLoadingSerial] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [selectedSerialNumber, setSelectedSerialNumber] = useState(
    null as { value: string; label: string } | null
  );
  const [state, updateState] = useState({
    remarks: null as string | null,
    condition: null as string | null,
  });
  const updateForm = (name: string, value: string) => {
    updateState({
      ...state,
      [name]: value,
    });
  };
  const handleChange = (selectedOption: any, setFunction: Function) => {
    setFunction(selectedOption);
  };
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (item) {
      setSubmitLoading(true);
      postItemReturn(
        item.itemName,
        item.serialNumber,
        item.project,
        item.issueTo,
        state.remarks as string,
        state.condition as string
      )
        .then((success) => {
          if (success) {
            setMessage("Item has been successfully returned");
            setOpen(true);
            setSerialNumbers(null);
            setItem(null);
            updateState({ remarks: null, condition: null });
            setSubmitLoading(false);
          } else {
            setMessage(
              "Something went wrong. Item might not be working, or status is in"
            );
            setOpen(true);
            setSubmitLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setMessage(
            "Something went wrong. Item might not be working, or status is in"
          );
          setOpen(true);
          setSubmitLoading(false);
        });
    }
  };

  // Load equipments that are in store
  useEffect(() => {
    getItemsOut().then((itemNames: string[]) => {
      let itemsDict: any = itemNames
        .sort()
        .map((x: any) => ({ value: x, label: x }));
      setItems(itemsDict);
      setIsLoading(false);
    });
  }, []);

  // Load equipment serial numbers that are in store
  useEffect(() => {
    if (selectedItem) {
      setIsLoadingSerial(true);
      setSerialNumbers(null);
      setItem(null);
      getSerialNumbersOut(selectedItem.value as string).then(
        (serialNumbersOut: string[]) => {
          let serialNumbersDict: any = serialNumbersOut
            .sort()
            .map((x: any) => ({ value: x, label: x }));
          setSerialNumbers(serialNumbersDict);
          setIsLoadingSerial(false);
        }
      );
    }
  }, [selectedItem]);

  // Load item data
  useEffect(() => {
    if (selectedItem && selectedSerialNumber) {
      setIsLoadingForm(true);
      getUniqueItem(selectedItem.value, selectedSerialNumber.value).then(
        (item: EquipmentItem) => {
          setItem(item);
          setIsLoadingForm(false);
        }
      );
    }
    // eslint-disable-next-line
  }, [selectedSerialNumber]);

  return (
    <>
      {isLoading && (
        <div style={{ margin: 20, textAlign: "center" }}>
          <CircularProgress color="primary" />
        </div>
      )}
      {!isLoading && (
        <>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={open}
            onClose={() => setOpen(false)}
            autoHideDuration={3000}
            message={message}
          />
          <Paper style={{ padding: 10 }}>
            <div style={{ margin: 20 }}>
              <h2>Equipment Returns</h2>
              <span>Item to return</span>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isLoading={isLoading}
                isClearable={true}
                isSearchable={true}
                name="color"
                options={items}
                onChange={(selectedOption) =>
                  handleChange(selectedOption, setSelectedItem)
                }
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </div>
            {isLoadingSerial && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress color="primary" size={20} />
              </div>
            )}
            {serialNumbers && !isLoadingSerial && (
              <div style={{ margin: 20 }}>
                <span>Serial Number</span>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isLoading={isLoading}
                  isClearable={true}
                  isSearchable={true}
                  name="color"
                  options={serialNumbers}
                  onChange={(selectedOption) =>
                    handleChange(selectedOption, setSelectedSerialNumber)
                  }
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                />
              </div>
            )}
          </Paper>
          {isLoadingForm && (
            <div style={{ textAlign: "center", margin: 20 }}>
              <CircularProgress color="primary" size={30} />
            </div>
          )}
          {item && !isLoadingForm && (
            <ReturnForm
              item={item}
              state={state}
              submitLoading={submitLoading}
              updateForm={updateForm}
              handleSubmit={handleSubmit}
            />
          )}
        </>
      )}
    </>
  );
};

export default ReturnItem;
