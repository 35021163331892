import axios from "axios";
import moment from "moment-timezone";
import { EquipmentItem } from "../../models/equipmentItem";

export const getItems = (): Promise<EquipmentItem[]> => {
  return new Promise<EquipmentItem[]>((resolve, reject) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/eq/items`,
      withCredentials: true,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        const items: EquipmentItem[] = data.map((x: any) => {
          return {
            sn: x.sn,
            status: x.status,
            category: x.category,
            bundle: x.bundle === null ? "-" : x.bundle,
            itemName: x.itemname,
            model: x.model,
            serialNumber: x.serialnumber,
            project: x.project,
            issueTo: x.issueto,
            returnBy: x.returnby
              ? moment.utc(x.returnby).local().format("DD/MM/YYYY, HH:mm:ss")
              : null,
            remarks: x.remarks,
            imageUrl: x.image_url,
            working: x.working === "true" ? "Working" : "Not working",
            count: x.count,
          };
        });
        resolve(items);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getItemById = (id: number): Promise<EquipmentItem> => {
  return new Promise<EquipmentItem>((resolve, reject) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/eq/items/${id}`,
      withCredentials: true,
    })
      .then((res) => res.data)
      .then((x: any) => {
        console.log("API OK");
        const item: EquipmentItem = {
          sn: x.sn,
          status: x.status,
          category: x.category,
          bundle: x.bundle === null ? "-" : x.bundle,
          itemName: x.itemname,
          model: x.model,
          serialNumber: x.serialnumber,
          project: x.project,
          issueTo: x.issueto,
          returnBy: x.returnby,
          remarks: x.remarks,
          imageUrl: x.image_url,
          working: x.working === "true" ? "Working" : "Not working",
          count: x.count,
        };
        resolve(item);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createItem = (state: {
  itemName: string | null;
  category: string | null;
  bundle: string | null;
  imageUrl: string | null;
  model: string | null;
  serialNumber: string | null;
  remarks: string | null;
}): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/eq/items`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      data: {
        itemname: state.itemName,
        category: state.category,
        bundle: state.bundle,
        image_url: state.imageUrl,
        model: state.model,
        serialnumber: state.serialNumber,
        remarks: state.remarks,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data.success);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateItem = (item: EquipmentItem): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/eq/items`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      data: {
        itemname: item.itemName,
        category: item.category,
        bundle: item.bundle,
        image_url: item.imageUrl,
        model: item.model,
        serialnumber: item.serialNumber,
        status: item.status,
        project: item.project,
        issueto: item.issueTo,
        returnby: item.returnBy,
        remarks: item.remarks,
        working: item.working,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data.success);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteItem = (
  itemname: string,
  serialnumber: string
): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/eq/items`,
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      data: { itemname, serialnumber },
    })
      .then((res) => res.data)
      .then((data) => {
        console.log("API OK");
        resolve(data.success);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
