import React, { FormEvent, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { EquipmentItem } from "../../../models/equipmentItem";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import CircularProgress from "@material-ui/core/CircularProgress";

const ReturnForm = (props: {
  item: EquipmentItem;
  state: {
    remarks: string | null;
    condition: string | null;
  };
  submitLoading: boolean;
  updateForm: (name: string, value: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}) => {
  const { item, state, submitLoading, updateForm, handleSubmit } = props;
  const [workingButton, setWorkingButton] = useState("true");
  const handleToggle = (e: any, newButton: string, setFunction: Function) => {
    if (newButton !== null) setFunction(newButton);
  };
  return (
    <Paper style={{ padding: 10, marginTop: 20 }}>
      <div style={{ margin: 20 }}>
        <h2>Enter details to return {item.itemName}</h2>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Item Name"
            value={item.itemName || ""}
            autoFocus
            disabled
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="type"
            label="Item Type"
            value={item.category || ""}
            autoFocus
            disabled
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="set"
            label="Item Set"
            value={item.bundle || ""}
            autoFocus
            disabled
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="model"
            label="Model"
            value={item.model || ""}
            autoFocus
            disabled
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="serialNumber"
            label="Serial Number"
            value={item.serialNumber || ""}
            autoFocus
            disabled
          />
          <TextField
            name="project"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="project"
            label="Project"
            value={item.project || ""}
            disabled
          />
          <TextField
            name="issueTo"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="issueTo"
            label="Issue To"
            value={item.issueTo || ""}
            disabled
          />
          <TextField
            name="remarks"
            variant="outlined"
            margin="normal"
            fullWidth
            id="remarks"
            label="Remarks"
            value={state.remarks || ""}
            onChange={(e: any) => updateForm(e.target.name, e.target.value)}
            autoFocus
          />
          <div style={{ textAlign: "center" }}>
            <ToggleButtonGroup
              onChange={(event: any, value: string) => {
                handleToggle(event, value, setWorkingButton);
                updateForm("condition", value);
              }}
              value={workingButton}
              exclusive
              aria-label="text alignment"
            >
              <ToggleButton
                size="medium"
                value="true"
                onClick={() => setWorkingButton("true")}
              >
                Working
              </ToggleButton>
              <ToggleButton
                size="medium"
                value="false"
                onClick={() => setWorkingButton("false")}
              >
                Not Working
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          {!submitLoading && (
            <Button
              fullWidth
              disableElevation
              type="submit"
              variant="contained"
              color="secondary"
              style={{ marginTop: 20 }}
            >
              Return Item
            </Button>
          )}
          {submitLoading && (
            <div style={{ textAlign: "center", margin: 20 }}>
              <CircularProgress color="primary" size={30} />
            </div>
          )}
        </form>
      </div>
    </Paper>
  );
};

export default ReturnForm;
