import React, { FormEvent, useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import logo from "../../../assets/bb_logo_2.png";
import Slider from "react-slick";
import styled from "styled-components";
import Snackbar from "@material-ui/core/Snackbar";
import QRModal from "./QRModal";
import SelectWithdrawReturnSlide from "./SelectWithdrawReturnSlide";
import ScanSetSlide from "./ScanSetSlide";
import ScanItemSlide from "./ScanItemSlide";
import WithdrawForm from "./WithdrawForm";
import { store } from "../../../redux/store";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  getItemsFromBundle,
  scanReturnItems,
  scanWithdrawItems,
} from "../../../api/equipment/scan";
import { EquipmentItem } from "../../../models/equipmentItem";
import { getItemById } from "../../../api/equipment/items";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderWrap = styled.div`
  .slick-slide,
  .slick-slide * {
    outline: none !important;
  }
`;

export default function Scan() {
  const classes = useStyles();
  const initialState = {
    sns: null as string[] | null,
    bundle: "",
    issueto: "",
    project: "",
    remarks: "",
  };
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showInput, setShowInput] = useState(true);
  const [scanLoading, setScanLoading] = useState(false);
  const [state, updateState] = useState(initialState);
  const [openItemModal, setOpenItemModal] = useState(false);
  const [openBundleModal, setOpenBundleModal] = useState(false);
  const [slider, setSlider] = useState(null as Slider | null);
  const [sn, setSn] = useState("");
  const [itemInOut, setItemInOut] = useState(null as string | null);
  const [itemsInSet, setItemsInSet] = useState(null as EquipmentItem[] | null);
  const [messageOpen, setMessageOpen] = React.useState(false);
  const [user, setUser] = useState({ username: "", name: "" });
  const [itemsOnly, setItemsOnly] = useState(false);
  const selectWithdrawReturn = (type: string) => {
    setItemInOut(type);
    goToNext();
  };
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    adaptiveHeight: true,
  };
  useEffect(() => {
    const authState = store.getState().auth;
    setUser({ username: authState.username, name: authState.name });
  }, []);
  useEffect(() => {
    updateState((state: any) => ({
      ...state,
      issueto: user.name,
    }));
  }, [user]);
  const submitForm = (event: FormEvent) => {
    event.preventDefault();
    console.log("Submitted");
    setLoading(true);
    // eslint-disable-next-line
    const { sns, bundle, issueto, project, remarks } = state;
    if (itemInOut === "withdraw") {
      scanWithdrawItems(sns, issueto, project, remarks)
        .then((data: any) => {
          if (data.success) {
            setMessage("Withdraw success");
            setMessageOpen(true);
            setItemsOnly(false);
            setShowInput(true);
            updateState(initialState);
            setLoading(false);
            setItemInOut(null);
            goToNext();
          } else {
            setLoading(false);
            setMessage("Something went wrong, please try again");
            setMessageOpen(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setMessage("Something went wrong, please try again");
          setMessageOpen(true);
        });
    } else if (itemInOut === "return") {
      scanReturnItems(sns)
        .then((data: any) => {
          if (data.success) {
            setMessage("Return success");
            setMessageOpen(true);
            setItemsOnly(false);
            setShowInput(true);
            updateState(initialState);
            setLoading(false);
            setItemInOut(null);
            goToNext();
          } else {
            setLoading(false);
            setMessage("Something went wrong, please try again");
            setMessageOpen(true);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          setMessage("Something went wrong, please try again");
          setMessageOpen(true);
        });
    }
  };
  const scanSet = (data: string) => {
    if (data) {
      setScanLoading(true);
      let setNo = data.includes("Set ") ? data : "Set " + data;
      getItemsFromBundle(setNo).then((items: any) => {
        if (items === "No items in the set") {
          setMessage("Set does not exist");
          setMessageOpen(true);
          setScanLoading(false);
        } else {
          updateState({
            ...state,
            bundle: setNo,
            sns: null,
          });
          setShowInput(true);
          setItemsInSet(items);
          setScanLoading(false);
          goToNext();
        }
      });
      setOpenBundleModal(false);
    }
  };
  const scanItem = (data: string) => {
    if (data) {
      setShowInput(false);
      let snArray = state.sns;
      if (itemsInSet) {
        let snFromSet = itemsInSet.map((x: EquipmentItem) => x.sn.toString());
        if (snFromSet && snFromSet.includes(data)) {
          if (snArray && snArray.includes(data)) {
            setMessage("Item already scanned");
            setMessageOpen(true);
            setSn("");
          } else {
            snArray ? snArray.push(data) : (snArray = [data]);
            updateState(Object.assign({}, state, { sns: snArray }));
            setSn("");
          }
        } else {
          setMessage("Item not in set");
          setMessageOpen(true);
          setSn("");
          if (!state.sns) setShowInput(true);
          if (state.sns && !state.sns[0]) setShowInput(true);
        }
      }
      setOpenItemModal(false);
    }
  };
  const scanItemWithoutSet = (data: string) => {
    if (data) {
      setShowInput(false);
      let snArray = state.sns;
      if (snArray && snArray.includes(data)) {
        setMessage("Item already scanned");
        setMessageOpen(true);
        setSn("");
      } else {
        getItemById(parseInt(data))
          .then((item: EquipmentItem) => {
            let newItemsInSet = itemsInSet ? itemsInSet : [];
            newItemsInSet.push(item);
            setItemsInSet(newItemsInSet);
            snArray ? snArray.push(data) : (snArray = [data]);
            updateState(Object.assign({}, state, { sns: snArray }));
            setSn("");
          })
          .catch((err) => {
            console.log(err);
            setMessage("Item does not exist");
            setMessageOpen(true);
            setShowInput(true);
          });
      }
      setOpenItemModal(false);
    }
  };
  const updateForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    updateState({
      ...state,
      [name]: value,
    });
  };
  const goToNext = () => {
    if (slider) slider.slickNext();
  };
  return (
    <>
      <QRModal
        open={openBundleModal}
        setOpen={setOpenBundleModal}
        scanType={"bundle"}
        handleScan={scanSet}
      />
      <QRModal
        open={openItemModal}
        setOpen={setOpenItemModal}
        scanType={"sn"}
        handleScan={itemsOnly ? scanItemWithoutSet : scanItem}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={messageOpen}
        onClose={() => setMessageOpen(false)}
        autoHideDuration={3000}
        message={message}
      />
      <div className={classes.logo}>
        <Avatar className={classes.avatar} src={logo} />
        <Typography component="h1" variant="h6">
          Beng Beng Electrical
        </Typography>
        {itemInOut && (
          <>
            <h3>
              {itemInOut === "withdraw" ? "Withdraw Items" : "Return Items"}
              {state.bundle && ` | ${state.bundle}`}
            </h3>
          </>
        )}
        {!itemInOut && <h3>Select withdraw / return</h3>}
      </div>
      <SliderWrap>
        <Slider {...settings} ref={(slider) => setSlider(slider)}>
          <div>
            <SelectWithdrawReturnSlide
              selectWithdrawReturn={selectWithdrawReturn}
            />
          </div>

          {itemInOut && (
            <div>
              <ScanSetSlide
                bundle={state.bundle}
                scanSet={scanSet}
                updateForm={updateForm}
                setOpenBundleModal={setOpenBundleModal}
                scanLoading={scanLoading}
                setItemsOnly={setItemsOnly}
                goToNext={goToNext}
              />
            </div>
          )}

          {itemInOut && (
            <div>
              <ScanItemSlide
                state={state}
                itemsOnly={itemsOnly}
                itemsInSet={itemsInSet}
                showInput={showInput}
                setShowInput={setShowInput}
                updateState={updateState}
                scanItem={scanItem}
                scanItemWithoutSet={scanItemWithoutSet}
                sn={sn}
                setSn={setSn}
                setOpenItemModal={setOpenItemModal}
                itemInOut={itemInOut}
                loading={loading}
                submitForm={submitForm}
                goToNext={goToNext}
              />
            </div>
          )}

          {itemInOut === "withdraw" && (
            <div>
              <WithdrawForm
                loading={loading}
                state={state}
                submitForm={submitForm}
                updateForm={updateForm}
              />
            </div>
          )}
        </Slider>
      </SliderWrap>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    border: "3px solid red",
  },
}));
