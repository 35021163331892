import React, { FormEvent } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { EquipmentItem } from "../../../models/equipmentItem";

const WithdrawalForm = (props: {
  item: EquipmentItem;
  state: {
    project: string | null;
    issueTo: string | null;
    remarks: string | null;
  };
  submitLoading: boolean;
  updateForm: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}) => {
  const { item, state, submitLoading, updateForm, handleSubmit } = props;
  return (
    <Paper style={{ padding: 10, marginTop: 20 }}>
      <div style={{ margin: 20 }}>
        <h2>Enter details to withdraw {item.itemName}</h2>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Item Name"
            value={item.itemName || ""}
            autoFocus
            disabled
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="type"
            label="Item Type"
            value={item.category || ""}
            autoFocus
            disabled
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="set"
            label="Item Set"
            value={item.bundle || ""}
            autoFocus
            disabled
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="model"
            label="Model"
            value={item.model || ""}
            autoFocus
            disabled
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="serialNumber"
            label="Serial Number"
            value={item.serialNumber || ""}
            autoFocus
            disabled
          />
          <TextField
            name="project"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="project"
            label="Project"
            value={state.project || ""}
            onChange={updateForm}
            autoFocus
          />
          <TextField
            name="issueTo"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="issueTo"
            label="Issue To"
            value={state.issueTo || ""}
            onChange={updateForm}
          />
          <TextField
            name="remarks"
            variant="outlined"
            margin="normal"
            fullWidth
            id="remarks"
            label="Remarks"
            value={state.remarks || ""}
            onChange={updateForm}
          />
          {!submitLoading && (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              style={{ marginTop: 20 }}
            >
              Withdraw Item
            </Button>
          )}
          {submitLoading && (
            <div style={{ textAlign: "center", margin: 20 }}>
              <CircularProgress color="primary" size={30} />
            </div>
          )}
        </form>
      </div>
    </Paper>
  );
};

export default WithdrawalForm;
