import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import StoreMallDirectoryOutlinedIcon from "@material-ui/icons/StoreMallDirectoryOutlined";
import EvStationOutlinedIcon from "@material-ui/icons/EvStationOutlined";
import CropFreeIcon from "@material-ui/icons/CropFree";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import EjectOutlinedIcon from "@material-ui/icons/EjectOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SubjectOutlinedIcon from "@material-ui/icons/SubjectOutlined";
import KeyboardReturnOutlinedIcon from "@material-ui/icons/KeyboardReturnOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const dashboardIconMap: { [key: string]: any } = {
  withdraw: <EjectOutlinedIcon fontSize="small" color="primary" />,
  return: <KeyboardReturnOutlinedIcon fontSize="small" color="primary" />,
  delivery: <EmailOutlinedIcon fontSize="small" color="primary" />,
  add: <AddOutlinedIcon fontSize="small" color="primary" />,
  edit: <EditOutlinedIcon fontSize="small" color="primary" />,
  remove: <RemoveOutlinedIcon fontSize="small" color="primary" />,
  equipment: <BuildOutlinedIcon fontSize="small" color="primary" />,
  invt: <StoreMallDirectoryOutlinedIcon fontSize="small" color="primary" />,
  gen: <EvStationOutlinedIcon fontSize="small" color="primary" />,
  transactions: <SubjectOutlinedIcon fontSize="small" color="primary" />,
  scan: <CropFreeIcon fontSize="small" color="primary" />,
};

const DrawerList = (props: {
  eqOpen: boolean;
  invtOpen: boolean;
  genOpen: boolean;
  setEqOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setInvtOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setGenOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDashboard: string;
  selectDashboard: (dashboard: string) => void;
  eqButton: string;
  eqButtons: { [key: string]: string };
  setEqButton: React.Dispatch<React.SetStateAction<string>>;
  invtButton: string;
  invtButtons: { [key: string]: string };
  setInvtButton: React.Dispatch<React.SetStateAction<string>>;
  genButton: string;
  genButtons: { [key: string]: string };
  setGenButton: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const classes = useStyles();
  const {
    selectedDashboard,
    selectDashboard,
    eqButton,
    eqButtons,
    setEqButton,
    invtButton,
    invtButtons,
    setInvtButton,
    genButton,
    genButtons,
    setGenButton,
    eqOpen,
    invtOpen,
    genOpen,
    setEqOpen,
    setInvtOpen,
    setGenOpen,
  } = props;
  const handleExpand = (
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
  ): void => {
    setOpen(!open);
  };
  return (
    <div>
      <ListItem
        button
        selected={selectedDashboard === "HOME"}
        onClick={() => selectDashboard("HOME")}
      >
        <ListItemIcon>
          <DashboardOutlinedIcon color={"secondary"} />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem button onClick={() => handleExpand(invtOpen, setInvtOpen)}>
        <ListItemIcon>
          <StoreMallDirectoryOutlinedIcon color={"secondary"} />
        </ListItemIcon>
        <ListItemText primary="Inventory" />
        {invtOpen ? (
          <ExpandLess color="primary" />
        ) : (
          <ExpandMore color="primary" />
        )}
      </ListItem>
      <Collapse in={invtOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {Object.keys(invtButtons).map((x: string, idx: number) => (
            <ListItem
              button
              key={idx}
              selected={invtButton === x}
              className={classes.nested}
              onClick={() => {
                selectDashboard("INVENTORY");
                setInvtButton(x);
              }}
            >
              <ListItemIcon>{dashboardIconMap[x]}</ListItemIcon>
              <ListItemText primary={Object.values(invtButtons)[idx]} />
            </ListItem>
          ))}
        </List>
      </Collapse>

      <ListItem button onClick={() => handleExpand(eqOpen, setEqOpen)}>
        <ListItemIcon>
          <BuildOutlinedIcon color={"secondary"} />
        </ListItemIcon>
        <ListItemText primary="Equipment" />
        {eqOpen ? (
          <ExpandLess color="primary" />
        ) : (
          <ExpandMore color="primary" />
        )}
      </ListItem>
      <Collapse in={eqOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {Object.keys(eqButtons).map((x: string, idx: number) => (
            <ListItem
              button
              key={idx}
              selected={eqButton === x}
              className={classes.nested}
              onClick={() => {
                selectDashboard("EQUIPMENT");
                setEqButton(x);
              }}
            >
              <ListItemIcon>{dashboardIconMap[x]}</ListItemIcon>
              <ListItemText primary={Object.values(eqButtons)[idx]} />
            </ListItem>
          ))}
        </List>
      </Collapse>

      <ListItem button onClick={() => handleExpand(genOpen, setGenOpen)}>
        <ListItemIcon>
          <EvStationOutlinedIcon color={"secondary"} />
        </ListItemIcon>
        <ListItemText primary="Generator" />
        {genOpen ? (
          <ExpandLess color="primary" />
        ) : (
          <ExpandMore color="primary" />
        )}
      </ListItem>
      <Collapse in={genOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {Object.keys(genButtons).map((x: string, idx: number) => (
            <ListItem
              button
              key={idx}
              selected={genButton === x}
              className={classes.nested}
              onClick={() => {
                selectDashboard("GENERATOR");
                setGenButton(x);
              }}
            >
              <ListItemIcon>{dashboardIconMap[x]}</ListItemIcon>
              <ListItemText primary={Object.values(genButtons)[idx]} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </div>
  );
};

export default DrawerList;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(4),
      height: 35,
    },
  })
);
