import React, { FormEvent } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import CropFreeIcon from "@material-ui/icons/CropFree";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, Theme } from "@material-ui/core/styles";

const ScanSetSlide = (props: {
  bundle: string;
  scanSet: (data: string) => void;
  updateForm: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setOpenBundleModal: React.Dispatch<React.SetStateAction<boolean>>;
  scanLoading: boolean;
  setItemsOnly: React.Dispatch<React.SetStateAction<boolean>>;
  goToNext: () => void;
}) => {
  const classes = useStyles();
  const {
    bundle,
    scanSet,
    updateForm,
    setOpenBundleModal,
    scanLoading,
    setItemsOnly,
    goToNext,
  } = props;
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Box width="100%">
          <form
            onSubmit={(event: FormEvent) => {
              event.preventDefault();
              scanSet(bundle);
            }}
          >
            <FormControl className={classes.form}>
              <InputLabel htmlFor="setnumber-input">
                SCAN OR TYPE SET NUMBER
              </InputLabel>
              <Input
                required
                id="setnumber-input"
                name="bundle"
                type="text"
                onChange={updateForm}
                value={bundle}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton
                      style={{ padding: 0, margin: 0 }}
                      onClick={() => {
                        setOpenBundleModal(true);
                      }}
                    >
                      <CropFreeIcon color="secondary" />
                    </IconButton>
                  </InputAdornment>
                }
                endAdornment={
                  <IconButton type="submit">
                    {!scanLoading && (
                      <AddCircleOutlineOutlinedIcon color="primary" />
                    )}
                    {scanLoading && (
                      <CircularProgress color="primary" size={25} />
                    )}
                  </IconButton>
                }
              />
              <div style={{ textAlign: "center", marginTop: 5 }}>
                <h3>or</h3>
              </div>
              <Button
                fullWidth
                disableElevation
                className={classes.submit}
                variant="contained"
                color="secondary"
                onClick={() => {
                  setItemsOnly(true);
                  goToNext();
                }}
                style={{ marginTop: 30 }}
              >
                SCAN ITEMS WITHOUT SET
              </Button>
            </FormControl>
          </form>
        </Box>
      </div>
    </Container>
  );
};

export default ScanSetSlide;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(10),
  },
  form: {
    display: "flex",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontFamily: "Lato",
  },
}));
