import React, { useEffect, useState } from "react";
import Title from "./Title";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getItems } from "../../api/equipment/items";
import { EquipmentItem } from "../../models/equipmentItem";

export default function Equipment() {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState(null as EquipmentItem[] | null);
  const [itemsNotWorking, setItemsNotWorking] = useState(
    null as EquipmentItem[] | null
  );
  useEffect(() => {
    getItems().then((items: EquipmentItem[]) => {
      setItems(items);
    });
  }, []);
  useEffect(() => {
    console.log(items);
    if (items) {
      let itemsNotWorking = items.filter(
        (x: any) => x.working === "Not working"
      );
      setItemsNotWorking(itemsNotWorking);
      setLoading(false);
    }
  }, [items]);
  return (
    <>
      <Title>Faulty Equipment</Title>
      {loading && (
        <div style={{ textAlign: "center", margin: 20 }}>
          <CircularProgress color="primary" size={30} />
        </div>
      )}
      {!loading && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>S/N</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Set</TableCell>
              <TableCell>Item Name</TableCell>
              <TableCell>Model</TableCell>
              <TableCell align="right">Serial Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemsNotWorking &&
              itemsNotWorking.map((item: EquipmentItem) => (
                <TableRow key={item.sn}>
                  <TableCell>{item.sn}</TableCell>
                  <TableCell>{item.category}</TableCell>
                  <TableCell>{item.bundle}</TableCell>
                  <TableCell>{item.itemName}</TableCell>
                  <TableCell>{item.model}</TableCell>
                  <TableCell align="right">{item.serialNumber}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}
