import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import Login from "../components/Login";
import PrivateRoute from "../PrivateRoute";

const ApplicationRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        {/* Disallow resetting password for now */}
        {/* <Route exact path="/reset" component={Reset} /> */}
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
      </Switch>
    </Router>
  );
};

export default ApplicationRoutes;
